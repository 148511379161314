<template>
  <div class="menu-bar">
    <div class="icons-main" @click="openAbout()">
      <div class="icon-visibility-container">
        <div class="icon icon-main-md"></div>
      </div>
    </div>

    <div v-show="loggedIn" class="icons-nav collapseable">
      <buttonc
        v-for="(icon, i) in iconsNav"
        type="menu"
        :key="i"
        :icon="icon.icon"
        :label="icon.label"
        :link="icon.link"
        :active="isActive(icon.link)"
        @click="
          ToggleDropDown($event, icon.items, 0, 10);
          $forceUpdate();
        "
      />
      <buttonc class="collapsed" type="menu" icon="fa-cog" label="Menü" @click="ToggleDropDown($event, iconsNav, 0, 10)" />
    </div>
    <div class="icons-left" v-show="loggedIn"></div>
    <div v-if="loggedIn || loggedInNoCompanies" class="login">
      <dropdownsearch :state="dropdownStoreState" style="width: 230px; margin: 0px 8px" />

      <!--
            <buttonc type="menu" label="" icon="far fa-plus-hexagon" @click="navigateToNotifications"/>
            <div style="background-color:#e51550; width: 17px; height:17px; font-size:9px; float:left; margin-left:-23px; border-radius:20px; top:13px; color:#fff; line-height:17px; text-align:center;">
                {{ newEmployeeCount }}
            </div>
            -->

      <div v-if="!loggedInNoCompanies" class="fas fa-bell" role="button" @click="showNotificationsPopup">
        <div v-if="notificationCount > 0" class="notification" v-bind:class="{ big: notificationCount > 99 }">
          {{ notificationCount }}
        </div>
      </div>

      <div class="dividerh"></div>

      <div v-if="!loggedInNoCompanies" class="fas fa-filter" role="button" @click="showGlobalFilterPopup"></div>

      <div class="dividerh"></div>

      <div v-if="!loggedInNoCompanies" class="fas fa-lightbulb" role="button" @click="toggleDarkMode()"></div>

      <div class="dividerh"></div>

      <div class="menu" v-on:click="OpenUserMenu($event)">
        <employeepicture :pictureUrl="my_image" />
        <div v-if="eventNumber > 0" class="notification" v-bind:class="{ big: eventNumber > 99 }">
          {{ eventNumber }}
        </div>
      </div>
    </div>

    <dropdownlogin ref="dropdownlogin" />
    <dropdown ref="dropdown" v-for="(dropdown, i) in dropdowns" :dropdown="dropdown" :key="i" />

    <iframe id="authframe" style="display: none" src="https://account.meindienstplan.at/lsproxy.html" width="10" height="10">
      <!-- Auth IFrame -->
    </iframe>
    <accountPreferences ref="accountPreferences" />

    <!-- Modals -->
    <b-modal ref="modal-startup" @close="closeStartupModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Hinweis zu Ihrem Browser</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span
              ><b>ACHTUNG: Manche Features funktionieren in Ihrem Browser möglicherweise nicht korrekt!</b><br /><br />Sieht aus als würden Sie Safari verwenden, MEINDIENSTPLAN Web
              ist für Google Chrome optimiert da dieser Browser für alle Plattformen verfügbar ist und die größte Verbreitung aufweist.<br /><br />Um alle Funktionen optimal nutzen
              zu können verwenden Sie bitte Google Chrome.</span
            >
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button green right" @click="closeStartupModal()">
          <div class="label">Verstanden</div>
        </div>
      </template>
    </b-modal>

    <b-modal ref="modal-nopermissions" @close="closeNoPermissionsModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Keine Berechtigung</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span
              >Sie haben nicht die notwendige Benutzerrolle um einen Betrieb in MEIN<b>DIENSTPLAN</b> zu öffnen. Ein Administrator Ihres Unternehmens kann Ihnen die notwendigen
              Benutzerrollen für Ihre Betriebe zuweisen.</span
            >
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button green right" @click="closeNoPermissionsModal()">
          <div class="label">Verstanden</div>
        </div>
      </template>
    </b-modal>

    <b-modal ref="modal-notloggedin" @close="closeNotLoggedInModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Login abgelaufen</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span>Ihre Anmeldung ist nicht mehr gültig. Bitte melden Sie sich auf der Account-Seite neu an.</span>
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button green right" @click="closeNotLoggedInModal()">
          <div class="label">zur Account-Seite</div>
        </div>
      </template>
    </b-modal>

    <!-- notifications modals-->
    <b-modal size="lg" ref="modal-newemployees" @close="closeNewEmployeesModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Folgende MitarbeiterInnen wurden neu importiert</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th :style="[!employeeSyncSettingsData.hasOwnProperty('disabled') ? { width: '40%' } : {width: '60%' }]"
                  style="font-family: DistrictProBold">Mitarbeiter</th>
              <th :style="[!employeeSyncSettingsData.hasOwnProperty('disabled') ? { width: '39%' } : {width: '40%' }]"
                  style="font-family: DistrictProBold">Aktionen</th>
              <th v-if="!employeeSyncSettingsData.hasOwnProperty('disabled')" style="width: 21%; font-family: DistrictProBold">Export</th>
            </tr>
            <tr>
              <td></td>
              <td>
                <div style="padding: 1px">
                  <button style="width: 50%; margin-left: 50%" type="button" class="btn btn-sm btn-ml"
                          v-on:click="employeeSetAllRead()">Alle gesehen</button>
                </div>
              </td>
              <td v-if="!employeeSyncSettingsData.hasOwnProperty('disabled')">
                <button v-if="downloading !== 'all'" type="button" class="btn btn-sm btn-ml"
                        :style="[employeeSyncSettingsData.enableExportViaTransferService ? { width: '49%' } : { width: '100%' } ]"
                        style="margin-left: 1%"
                        :disabled="exporting !== null || downloading !== null"
                        v-on:click="downloadCSV(newEmployees)">Alle <i class='fas fa-file-download' /></button>
                <button v-if="downloading === 'all'" type="button" class="btn btn-sm btn-ml"
                        :style="[employeeSyncSettingsData.enableExportViaTransferService ? { width: '49%' } : { width: '100%' } ]"
                        style="margin-left: 1%">
                        <i class='fas fa-spinner fa-spin'></i></button>
                <button v-if="exporting !== 'all' && employeeSyncSettingsData.enableExportViaTransferService"
                        style="width: 48%; margin-left: 2%" type="button" class="btn btn-sm btn-ml"
                        :disabled="exporting !== null || downloading !== null"
                        v-on:click="exportCSV(newEmployees)">Alle <i class='fas fa-file-export' /></button>
                <button v-if="exporting === 'all' && employeeSyncSettingsData.enableExportViaTransferService"
                        style="width: 48%; margin-left: 2%" type="button" class="btn btn-sm btn-ml">
                        <i class='fas fa-spinner fa-spin'></i></button>
              </td>
            </tr>
            <tr v-for="emp in newEmployees" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 49%; margin-right: 1%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.employeeId);
                      closeNewEmployeesModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                  <button style="width: 50%" type="button" class="btn btn-sm btn-ml" v-on:click="employeeSetRead(emp.employeeId)">gesehen</button>
                </div>
              </td>
              <td v-if="!employeeSyncSettingsData.hasOwnProperty('disabled')">
                <button v-if="downloading !== emp.employeeId" type="button" class="btn btn-sm btn-ml"
                        :style="[employeeSyncSettingsData.enableExportViaTransferService ? { width: '49%' } : { width: '100%' } ]"
                        style="margin-left: 1%"
                        :disabled="exporting !== null || downloading !== null"
                        v-on:click="downloadCSV(emp.employeeId)"><i class='fas fa-file-download' /></button>
                <button v-if="downloading === emp.employeeId" type="button" class="btn btn-sm btn-ml"
                        :style="[employeeSyncSettingsData.enableExportViaTransferService ? { width: '49%' } : { width: '100%' } ]"
                        style="margin-left: 1%"><i class='fas fa-spinner fa-spin'></i></button>
                <button v-if="exporting !== emp.employeeId && employeeSyncSettingsData.enableExportViaTransferService"
                        style="width: 48%; margin-left: 2%" type="button" class="btn btn-sm btn-ml"
                        :disabled="exporting !== null || downloading !== null"
                        v-on:click="exportCSV(emp.employeeId)"><i class='fas fa-file-export' /></button>
                <button v-if="exporting === emp.employeeId && employeeSyncSettingsData.enableExportViaTransferService"
                        style="width: 48%; margin-left: 2%" type="button" class="btn btn-sm btn-ml">
                        <i class='fas fa-spinner fa-spin'></i></button>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeNewEmployeesModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-canceledContracts" @close="closeCanceledContractsModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Der Arbeitsvertrag wurde bei folgenden Mitarbeitern beendet</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th style="width: 70%; font-family: DistrictProBold">Mitarbeiter</th>
              <th style="width: 30%; font-family: DistrictProBold">Aktionen</th>
            </tr>
            <tr>
              <td></td>
              <td>
                <div style="padding: 1px">
                  <button style="width: 99.7%" type="button" class="btn btn-sm btn-ml" v-on:click="canceledContractSetAllRead()">Alle gesehen</button>
                </div>
              </td>
            </tr>
            <tr v-for="emp in canceledContracts" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 59%; margin-right: 1%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.employeeId);
                      closeCanceledContractsModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                  <button style="width: 40%" type="button" class="btn btn-sm btn-ml" v-on:click="canceledContractSetRead(emp.employeeId)">gesehen</button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeCanceledContractsModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-socialInsuranceRegistration" @close="closeSocialInsuranceRegModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Folgende MitarbeiterInnen sind bereit zur ÖGK Anmeldung</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th style="width: 70%; font-family: DistrictProBold">Mitarbeiter</th>
              <th style="width: 30%; font-family: DistrictProBold">Aktionen</th>
            </tr>
            <tr v-for="emp in employeesWithoutSIR" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 100%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.employeeId);
                      closeSocialInsuranceRegModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeSocialInsuranceRegModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-missingData" @close="closeMissingDataModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Für folgende MitarbeiterInnen werden noch Daten benötigt</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th style="width: 70%; font-family: DistrictProBold">Mitarbeiter</th>
              <th style="width: 30%; font-family: DistrictProBold">Aktionen</th>
            </tr>
            <tr v-for="emp in employeesWithMinimalDataMissing" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 100%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.employeeId);
                      closeMissingDataModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeMissingDataModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-mdPersonnelIdDuplicates" @close="closeMdPersonnelIdDuplicatesModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Folgende MitarbeiterInnen haben bereits vorhandene Personalnummern</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th style="width: 40%; font-family: DistrictProBold">Mitarbeiter</th>
              <th style="width: 30%; font-family: DistrictProBold">Personalnummer</th>
              <th style="width: 30%; font-family: DistrictProBold">Aktionen</th>
            </tr>
            <tr v-for="emp in employeesWithDuplicateMdPersonnelIds()" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
                <small v-if="emp.issue" style="color: red;">{{ emp.issueText }}</small>
              </td>
              <td>
                <small>{{ emp.mdPersonnelId }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 100%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.id);
                      closeMdPersonnelIdDuplicatesModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeMdPersonnelIdDuplicatesModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-ibanDuplicates" @close="closeIbanDuplicatesModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Folgende MitarbeiterInnen haben bereits vorhandene IBANs</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th style="width: 40%; font-family: DistrictProBold">Mitarbeiter</th>
              <th style="width: 30%; font-family: DistrictProBold">IBAN</th>
              <th style="width: 30%; font-family: DistrictProBold">Aktionen</th>
            </tr>
            <tr v-for="emp in employeesWithDuplicateIbans()" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
                <small v-if="emp.issue" style="color: red;">{{ emp.issueText }}</small>
              </td>
              <td>
                <small>{{ emp.iban ? emp.iban.replace(/\s/g, '') : "" }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 100%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.id);
                      closeIbanDuplicatesModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeIbanDuplicatesModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-svnrDuplicates" @close="closeSvnrDuplicatesModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Folgende MitarbeiterInnen haben bereits vorhandene SV Nummern</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th style="width: 40%; font-family: DistrictProBold">Mitarbeiter</th>
              <th style="width: 30%; font-family: DistrictProBold">SV Nummer</th>
              <th style="width: 30%; font-family: DistrictProBold">Aktionen</th>
            </tr>
            <tr v-for="emp in employeesWithDuplicateSvnrs()" v-bind:key="emp.employeeId" class="my-1" style="text-align: left">
              <td>
                <small>{{ emp.lastName + " " + emp.firstName }}</small>
                <small v-if="emp.issue" style="color: red;">{{ emp.issueText }}</small>
              </td>
              <td>
                <small>{{ emp.socialsecurity ? emp.socialsecurity.replace(/\s/g, '') : "" }}</small>
              </td>
              <td>
                <div style="padding: 1px" class="col-lg-12">
                  <button
                    style="width: 100%"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    v-on:click="
                      navigateToEmployee(emp.id);
                      closeSvnrDuplicatesModal();
                    "
                  >
                    zum Mitarbeiter
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeSvnrDuplicatesModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>


    <b-modal size="lg" ref="missing-doc-employees" @close="closeMissingDocEmployeeModal()" centered>
        <template slot="modal-header">
            <div class="error-modal">
                <div class="headerlabel">{{missingDocModalTitle}}</div>
            </div>
        </template>
        <b-container fluid>
            <b-row class="my-1" style="text-align: left;">
                <div class="full">
                        <div class="label">{{missingDocModalDescription}}</div>
                </div>
            </b-row>
            <div class="full">
                <table style="width: 100%;">
                    <tr v-for="employeeId in missingDocumentModalEmployees" v-bind:key="employeeId" class="my-1" style="text-align: left;">
                        <td><small >{{employeeArray.find(e => e.id == employeeId) != undefined? (employeeArray.find(e => e.id == employeeId).lastName + ' ' + employeeArray.find(e => e.id == employeeId).firstName) : ''}}</small></td>
                    </tr>
                </table>
            </div>
        </b-container>
        <template slot="modal-footer">
            <div class="button right" @click="closeMissingDocEmployeeModal()">
                <div class="label">Schließen</div>
            </div>
        </template>
    </b-modal>
    <b-modal size="lg" ref="modal-docwarnings" @close="closeDocumentsModal()" centered>
        <template slot="modal-header">
            <div class="error-modal">
                <div class="headerlabel">Bitte folgende Dokumente beachten</div>
            </div>
        </template>
        <b-container fluid>
            <b-row v-if="unconfirmedDocuments.length > 0 && notificationBellConfigState.showUnconfirmedDocuments" class="my-1" style="text-align: left;">
                <div class="full">
                    <div class="header">
                        <div class="label" style="font-size: 16px; font-family: DistrictProBold; color: var(--col-warning);">Folgende Dokumente sind noch nicht bestätigt:</div>
                    </div>
                </div>
            </b-row>
            <div v-if="unconfirmedDocuments.length > 0 && notificationBellConfigState.showUnconfirmedDocuments" class="full split-bottom">
                <table style="width: 100%;">
                    <tr>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-warning);">Mitarbeiter</th>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-warning);">Dokument</th>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-warning);">Typ</th>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-warning);">Erstelldatum</th>
                    </tr>
                    <tr v-for="file in unconfirmedDocuments" v-bind:key="file.id" class="my-1" style="text-align: left;">
                        <td><small style="color: var(--col-warning)">{{employeeArray.find(e => e.id == file.employeeId) != undefined? (employeeArray.find(e => e.id == file.employeeId).lastName + ' ' + employeeArray.find(e => e.id == file.employeeId).firstName) : ''}}</small></td>
                        <td><small style="color: var(--col-warning)">{{file.name}}</small></td>
                        <td><small style="color: var(--col-warning)">{{$helpers.documentTypeLabel(file.documentType)}}</small></td>
                        <td><small style="color: var(--col-warning)">{{$helpers.extractDateStringTZ(file.creationDate, 'DD.MM.YYYY')}}</small></td>
                    </tr>
                </table>
            </div>
            <b-row v-if="expiredDocuments.length > 0 && notificationBellConfigState.showExpiredDocuments" class="my-1" style="text-align: left;">
                <div class="full">
                    <div class="header">
                        <div class="label" style="font-size: 16px; font-family: DistrictProBold; color: var(--col-red-text);">Folgende Dokumente sind abgelaufen:</div>
                    </div>
                </div>
            </b-row>
            <div v-if="expiredDocuments.length > 0 && notificationBellConfigState.showExpiredDocuments" class="full split-bottom">
                <table style="width: 100%;">
                    <tr>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-red-text);">Mitarbeiter</th>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-red-text);">Dokument</th>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-red-text);">Typ</th>
                        <th style="width: 25%; font-family: DistrictProBold;color: var(--col-red-text);">Ablaufdatum</th>
                    </tr>
                    <tr v-for="file in expiredDocuments" v-bind:key="file.id" class="my-1" style="text-align: left;">
                        <td><small style="color: var(--col-red-text)">{{employeeArray.find(e => e.id == file.employeeId) != undefined? (employeeArray.find(e => e.id == file.employeeId).lastName + ' ' + employeeArray.find(e => e.id == file.employeeId).firstName) : ''}}</small></td>
                        <td><small style="color: var(--col-red-text)">{{file.name}}</small></td>
                        <td><small style="color: var(--col-red-text)">{{$helpers.documentTypeLabel(file.documentType)}}</small></td>
                        <td><small style="color: var(--col-red-text)">{{$helpers.extractDateStringTZ(file.validUntil, 'DD.MM.YYYY')}}</small></td>
                    </tr>
                </table>
            </div>
            <b-row v-if="expiringSoonDocuments.length > 0 && notificationBellConfigState.showExpiringDocuments" class="my-1" style="text-align: left;">
                <div class="full">
                    <div class="header">
                        <div class="label" style="font-size: 16px; font-family: DistrictProBold; color: var(--col-warning);">Folgende Dokumente laufen bald ab:</div>
                    </div>
                </div>
            </b-row>
            <div v-if="expiringSoonDocuments.length > 0 && notificationBellConfigState.showExpiringDocuments" class="full">
                <table style="width: 100%;">
                    <tr>
                        <th style="width: 25%; font-family: DistrictProBold;">Mitarbeiter</th>
                        <th style="width: 25%; font-family: DistrictProBold;">Dokument</th>
                        <th style="width: 25%; font-family: DistrictProBold;">Typ</th>
                        <th style="width: 25%; font-family: DistrictProBold;">Ablaufdatum</th>
                    </tr>
                    <tr v-for="file in expiringSoonDocuments" v-bind:key="file.id" class="my-1" style="text-align: left;">
                        <td><small>{{employeeArray.find(e => e.id == file.employeeId) != undefined? (employeeArray.find(e => e.id == file.employeeId).lastName + ' ' + employeeArray.find(e => e.id == file.employeeId).firstName) : ''}}</small></td>
                        <td><small>{{file.name}}</small></td>
                        <td><small>{{$helpers.documentTypeLabel(file.documentType)}}</small></td>
                        <td><small>{{$helpers.extractDateStringTZ(file.validUntil, 'DD.MM.YYYY')}}</small></td>
                    </tr>
                </table>
            </div>

        </b-container>
        <template slot="modal-footer">
            <div class="button right" @click="closeDocumentsModal()">
                <div class="label">Schließen</div>
            </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import dropdown from "./uicomponents/dropdown.vue";
import dropdownlogin from "./uicomponents/dropdownlogin.vue";
import dropdownsearch from "./uicomponents/dropdownsearch.vue";
import accountPreferences from "./AccountPreferences.vue";
import VueEventBus from "../vue-event-bus";
import { mapState, mapGetters } from "vuex";
import { loadavg } from "os";
import Vue from "vue";
import VueMatomo from "vue-matomo";
import { parse } from "querystring";

export default {
  name: "MenuBar",
  components: {
    dropdown,
    dropdownlogin,
    dropdownsearch,
    accountPreferences,
  },
  data() {
    return {
      employeeArray: [],
      allEmployeesArray: [],

      newEmployees: [],
      canceledContracts: [],
      employeesWithoutSIR: [],
      employeesWithMinimalDataMissing: [],

      newEmployeeCount: 0,
      canceledContractsCount: 0,
      socialInsuranceRegistrationCount: 0,
      minimalDatafieldsNullCount: 0,
      issuesWithImportJobsCount: 0,
      mdPersonnelIdNotUniqueCount: 0,
      ibanNotUniqueCount: 0,
      svnrNotUniqueCount: 0,
      employeeExportCount: 0,

      mdPersonnelIdDuplicates: [],
      ibanDuplicates: [],
      svnrDuplicates: [],

      employeeEmployeeDocumentMap: {},
      expiredDocuments: [],
      expiringSoonDocuments: [],
      unconfirmedDocuments: [],
      missingPassDocs: [],
      missingWorkPermitDocs: [],
      missingResidencePermitDocs: [],
      missingEducationPermitDocs: [],
      missingDocModalTitle: "",
      missingDocModalDescription: "",
      missingDocumentModalEmployees: [],

      exporting: null,
      downloading: null,
      employeeSyncSettingsData: {
        disabled: true,
        enableExportViaTransferService: false
      },
      companyData: null,

      listeningChannels: [],
      warningShown: false,
      loggedInNoCompanies: false,
      authValidity: 86400, // 24 hours
      localStorageSupported: false,
      my_image: "https://fileserver1.meindienstplan.de/api/file?id=d55fd03c-9999-4a48-8515-906585d0bc0e&accessToken=ALPW6DGLB92U2U0S",
      fixedMainIcon: true,
      dropdownStoreState: {},
      dropdowns: [],
      processed: [],
      toggled: false,
      iconsMainCollapsed: false,
      eventNumber: 0,
      authFrame: null,
      selectedStores: [],
      user: {
        items: [
          {
            label: "Mein Account",
            externalLink: "https://account.meindienstplan.at",
          },
        ],
      },
      OnlineUserState: {
        type: "datafill",
        header: "Online:",
        tooltip: true,
        componentState: {
          displayOnly: true,
          entries: {},
        },
      },
      userData: {},
      refreshedCheck: false,
    };
  },
  computed: {
    ...mapGetters([
      "companyId",
      "isSuperUser",
      "loggedIn",
      "userInfo",
      "activeStoreUsersMap",
      "companyArray",
      "storeArray",
      "globalFilter",
      "externalSoftware",
      "notificationBellConfigState",
    ]),
    activeStoreUsers() {
      return Object.values(this.activeStoreUsersMap).filter((entry) => entry.id != this.userInfo.id);
    },

    notificationCount() {
      let num = 0;
      let config = this.notificationBellConfigState;
      if (config.showNewEmployees) num += this.newEmployeeCount;
      if (config.showCanceledContracts) num += this.canceledContractsCount;
      if (config.showSocialInsuranceRegistrations) num += this.socialInsuranceRegistrationCount;
      if (config.showMinimalDatafieldsNull) num += this.minimalDatafieldsNullCount;
      if (config.showIssuesWithImportJobsCount) num += this.issuesWithImportJobsCount;
      if (config.showMdPersonnelIdNotUniqueCount) num += this.mdPersonnelIdNotUniqueCount;
      if (config.showIbanNotUniqueCount) num += this.ibanNotUniqueCount;
      if (config.showSvnrNotUniqueCount) num += this.svnrNotUniqueCount;
      if (config.showEmployeeExportCount) num += this.employeeExportCount;

      if (config.showUnconfirmedDocuments) num += this.unconfirmedDocuments.length;
      if (config.showExpiredDocuments) num += this.expiredDocuments.length;
      if (config.showExpiringDocuments) num += this.expiringSoonDocuments.length;

      // check if at least 1 German store is selected
      let country = this.storeArray != null && this.storeArray.find(s => s.country == 2) ? 2 : 1;
      if(config.showMissingPassDocumentsForNonEU) num += this.missingPassDocs.length;
      if(config.showMissingWorkPermitDocumentsForNonEU) num += this.missingWorkPermitDocs.length;
      if(config.showMissingResidencePermitDocumentsForNonEU) num += this.missingResidencePermitDocs.length;
      if(config.showMissingEducationPermitDocumentsForNonEU && country == 2) num += this.missingEducationPermitDocs.length;

      return num;
    },
    loginUrl() {
      if (window.location.href.includes("localhost")) {
        return "https://account.meindienstplan.at/#/?redirect=localml";
      }

      let sub = this.getSubDomain();
      if (sub === "staging") {
        return "https://account.meindienstplan.at/#/?redirect=stagingml";
      } else if (sub === "development") {
        return "https://account.meindienstplan.at/#/?redirect=devml";
      }
      return "https://account.meindienstplan.at/#/?redirect=ml";
    },
    computedIconsMain() {
      let sub = this.getSubDomain();
      let urls = [];
      if (sub === "staging") {
        urls = [
          "https://account.meindienstplan.at/#/?redirect=devmdpl",
          "https://account.meindienstplan.at/#/?redirect=stagingmzlogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmplogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmdcorelogin",
        ];
      } else if (sub === "development") {
        urls = [
          "https://account.meindienstplan.at/#/?redirect=devmdpl",
          "https://account.meindienstplan.at/#/?redirect=stagingmzlogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmplogin",
          "https://account.meindienstplan.at/#/?redirect=stagingmdcorelogin",
        ];
      } else {
        urls = [
          "https://account.meindienstplan.at/#/?redirect=mdpl",
          "https://account.meindienstplan.at/#/?redirect=mzlogin",
          "https://account.meindienstplan.at/#/?redirect=mplogin",
          "https://account.meindienstplan.at/#/?redirect=mdcorelogin",
        ];
      }

      let iconsMain = [
        {
          icon: "icon-main-md",
          label: "Mein Dienstplan",
          externalLink: urls[0],
        },
        {
          icon: "icon-main-mz",
          label: "Meine Zeiterfassung",
          externalLink: urls[1],
        },
        { icon: "icon-main-mp", label: "Mein Player", externalLink: urls[2] },
        { icon: "fa-user", label: "Mein Kundencenter", externalLink: urls[3] },
      ];

      return iconsMain;
    },
    userFullname: function () {
      if (this.userInfo.firstname && this.userInfo.lastname) {
        return this.userInfo.firstname + " " + this.userInfo.lastname;
      } else if (this.userData.name) {
        return this.userData.name;
      } else {
        return "User";
      }
    },
    iconsNav: function () {
      let menu = [
        {
          label: "Personalakt",
          icon: "far fa-users",
          link: {
            name: "documents",
            params: {
              companyId: this.$store.state.companyId,
              tab: "employeedocuments",
            },
          },
        },
        {
          label: "Export",
          icon: "far fa-download",
          link: {
            name: "absence",
            params: {
              companyId: this.$store.state.companyId,
              tab: "ExportMonthly",
            },
          },
        },
        {
          label: "Abgleich",
          icon: "far fa-memo-circle-info",
          link: {
            name: "comparison",
            params: {
              companyId: this.$store.state.companyId,
              tab: "ComparisonHoliday",
            },
          },
        },
        {
          label: "Reports",
          icon: "far fa-file-signature",
          link: {
            name: "reports",
            params: {
              companyId: this.$store.state.companyId,
              tab: "ReportAbsences",
            },
          },
        },
        /* {label: 'Mitarbeiter', icon: 'far fa-users', link: {name: 'employee', params: {companyId: this.$store.state.companyId, tab: 'EmployeeDuplicateCleanup'}}}, */
        //{label: 'Reports', icon: 'fa-file-signature', link: {name: 'reports', params: {companyId: this.$store.state.companyId, tab: 'employeechangelog'}}},
        {
          label: "Einstellungen",
          icon: "far fa-cogs",
          link: {
            name: "company",
            params: {
              companyId: this.$store.state.companyId,
              tab: "CompanySettings",
            },
          },
        },
      ];
      if (this.isSuperUser) {
        menu.push({
          onlyAdmin: true,
          label: "Adminbereich",
          icon: "far fa-lock",
          link: {
            name: "superadmin",
            params: {
              companyId: this.$store.state.companyId,
              tab: "SuperadminCompany",
            },
          },
        });
      }
      return menu;
    },
    iconsNavCollapsed: function () {
      let menu = [
        {
          label: "Personalakt",
          icon: "far fa-users",
          link: {
            name: "documents",
            params: {
              companyId: this.$store.state.companyId,
              tab: "employeedocuments",
            },
          },
        },
        {
          label: "Export",
          icon: "far fa-download",
          link: {
            name: "absence",
            params: {
              companyId: this.$store.state.companyId,
              tab: "ExportMonthly",
            },
          },
        },
        {
          label: "Abgleich",
          icon: "far fa-memo-circle-info",
          link: {
            name: "comparison",
            params: {
              companyId: this.$store.state.companyId,
              tab: "ComparisonHoliday",
            },
          },
        },
        {
          label: "Reports",
          icon: "far fa-file-signature",
          link: {
            name: "reports",
            params: {
              companyId: this.$store.state.companyId,
              tab: "ReportAbsences",
            },
          },
        },
        /* {label: 'Mitarbeiter', icon: 'far fa-users', link: {name: 'employee', params: {companyId: this.$store.state.companyId, tab: 'EmployeeDuplicateCleanup'}}}, */
        //{label: 'Reports', icon: 'fa-file-signature', link: {name: 'reports', params: {companyId: this.$store.state.companyId, tab: 'employeechangelog'}}},
        {
          label: "Einstellungen",
          icon: "far fa-cogs",
          link: {
            name: "company",
            params: {
              companyId: this.$store.state.companyId,
              tab: "CompanySettings",
            },
          },
        },
      ];
      if (this.isSuperUser) {
        menu.push({
          onlyAdmin: true,
          label: "Adminbereich",
          icon: "far fa-lock",
          link: {
            name: "superadmin",
            params: {
              companyId: this.$store.state.companyId,
              tab: "SuperadminCompany",
            },
          },
        });
      }
      return menu;
    },
  },
  watch: {
    companyId(newVal, oldVal) {
      this.listenOnChannels();
      if (newVal) {
        this.$helpers.GetStoresSettings();
        this.loadPermissions();
        this.checkSuperUser();
        this.$parent.addAdditionalCompanyTabs(newVal);
      }
    },
    loggedIn(newVal, oldVal) {
      if (newVal) {
        this.loadPermissions();
        this.loadUserInfo();
        this.checkSuperUser();
        this.checkForStartupWarning();
      }
    },
    companyArray(newVal, oldVal) {
      if (this.refreshedCheck) this.updateCompanies(newVal);
    },
    loggedInNoCompanies(newVal, oldVal) {
      if (newVal) this.loadUserInfo();
    },
    activeStoreUsers(val, oldVal) {
      var changed = val.length != oldVal.length;

      if (!changed) {
        // check if new item in val that's not present in oldVal
        val.forEach((v) => {
          var hasMatch = false;
          oldVal.forEach((ov) => {
            if (this.userInfosEqual(v, ov)) {
              hasMatch = true;
              return;
            }
          });
          if (!hasMatch) {
            // new item
            changed = true;
            return;
          }
        });
      }
      if (!changed) {
        // check other way round
        oldVal.forEach((ov) => {
          var hasMatch = false;
          val.forEach((v) => {
            if (this.userInfosEqual(ov, v)) {
              hasMatch = true;
              return;
            }
          });
          if (!hasMatch) {
            // item removed
            this.$helpers.log(this, "active store user removed");
            changed = true;
            return;
          }
        });
      }
      if (changed) {
        this.updateOnlineUserState();
      }
    },
  },
  created() {
    window.menubar = this;
    this.checkForMaintenanceMode();
    this.$parent.addAdditionalCompanyTabs(this.$store.state.companyId);
    VueEventBus.$on("EmployeeSyncSettingsEvent:updated", this.updateEmployeeSyncSettingsData);
  },
  beforeDestroy: function(){
    VueEventBus.$off("EmployeeSyncSettingsEvent:updated", null);
  },
  mounted() {
    let view = this;
    let loginDone = false;
    this.initViewMode();
    if (this.supportsHTML5Storage()) {
      this.localStorageSupported = true;
      this.$store.commit("setNightMode", localStorage.getItem("uimode") === "2");
    }
    let jwt = false;
    if (this.localStorageSupported) {
      //get JWT from localstorage if it exists, store it in vuex then delete it from localstorage
      //this is needed for development environments were jwt needs to be set manually because our origin is localhost and cant be gotten from auth iframe
      jwt = localStorage.getItem("md_token");
      if (jwt != undefined) {
        this.$store.commit("setJWT", { jwt: jwt });
        if (!this.$helpers.isSafari() && !window.location.host.startsWith("localhost")) {
          localStorage.removeItem("md_token");
        }
      }
    }

    //let jwtqs = this.getQueryStringValue("jwt");
    let jwtqs = this.getJwtParam();
    if (!this.$helpers.isBlank(jwtqs)) {
      jwt = jwtqs;
      localStorage.setItem("md_token", jwtqs);
      this.$store.commit("setJWT", { jwt: jwtqs });
    }

      if (view.$helpers.isSafari()) {
        console.log('ML loading on Safari');
        if (jwt && jwt != "null") {
          let now = new Date().getTime() / 1000; // current time in seconds
          var redirect = false;
          var auth_ts = localStorage.getItem("auth_ts");
          if (auth_ts && auth_ts != undefined && auth_ts != "null") {
            var age = now - auth_ts;
            if (age > view.authValidity) {
              localStorage.removeItem("md_token"); // redirect to check logged in user
              redirect = true;
            }
          } else {
            localStorage.removeItem("md_token");
            redirect = true;
          }
          if (redirect) {
            localStorage.setItem("auth_ts", now);
            view.PrepareRedirectReturn();
            location.href = this.loginUrl;
          } else {
            view.login();
          }
        } else {
          view.PrepareRedirectReturn();
          window.location = this.loginUrl;
        }
      } else {
        if(!view.$helpers.isBlank(view.$store.state.jwt)){
          view.login();
        } else{
          window.location = this.loginUrl;
        }
      }

      if (window.location.host.startsWith("localhost")) {
          view.login();
        } else {

          window.onload = function() {

            let frame = document.getElementById("authframe");
            // some browser (don't remember which one) throw exception when you try to access
            // contentWindow for the first time, it work when you do that second time
            try {
              view.authframe = frame.contentWindow;
            } catch (e) {
              view.authframe = frame.contentWindow;
            }
            window.onmessage = function (e) {
              console.log("onmessage got triggered");
              if (e.origin === "https://account.meindienstplan.at") {
                let response = e.data;
                console.log("e.origin Response: ");
                console.log(response);
                if (response.key === "md_token") {
                  console.log("got token from account server");
                  view.$store.commit("setJWT", { jwt: response.data });
                  if (!view.$helpers.isBlank(view.$store.state.jwt) && !view.$store.state.loggedIn) {
                    console.log("It is blank, show login");
                    view.login();
                  } else {
                    console.log("Redirect");
                    view.PrepareRedirectReturn();
                    window.location = this.loginUrl;
                  }
                } else {
                  view.login();
                }
              }
            };
            // request JWT from auth iframe
            view.authframe.postMessage(JSON.stringify({ key: "md_token", method: "get" }), "*");
          };

        }




    this.getUserDataFromJWT();
  },
  methods: {
    getUserDataFromJWT() {
      let token = this.$store.state.jwt;
      if (token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        this.userData = JSON.parse(jsonPayload);
      }
    },
    initAnalytics() {
      let full = window.location.host;
      let parts = full.split(".");
      let subdomain = parts[0];
      let matomoSiteId = -1;
      this.getUserDataFromJWT();
      let userId = this.userData.sub;
      console.log("Analytics user id: " + userId);
      let enable = false;
      // matomo analytics not in local or dev environments
      if (window.location.href.includes("localhost")) {
        console.log("Analytics not enabled in localhost environments");
      } else if (subdomain !== "web") {
        matomoSiteId = 6;
        enable = true;
        console.log("Enabling analytics for " + subdomain + " environment using Matomo site ML DEV");
      } else if (subdomain === "web") {
        matomoSiteId = 5;
        enable = true;
        console.log("Enabling analytics for ML Live - siteId " + matomoSiteId);
      }
      if (enable) {
        Vue.config.productionTip = false;
        Vue.use(VueMatomo, {
          host: "https://analytics.meindienstplan.at/",
          siteId: matomoSiteId,
          trackerFileName: "matomo",
          router: this.$router,
          enableLinkTracking: true,
          requireConsent: false,
          trackInitialView: true,
          disableCookies: true,
          enableHeartBeatTimer: true,
          heartBeatTimerInterval: 15,
          debug: false,
          userId: userId,
        });
      }
    },
    PrepareRedirectReturn() {
      localStorage.setItem(
        "RedirectReturn",
        JSON.stringify({
          url: window.location.href,
          timeStamp: new Date().getTime(),
        })
      );
    },
    SetRedirectReturn() {
      let item = localStorage.getItem("RedirectReturn");
      if (item) {
        let data = JSON.parse(item);
        let timeDifference = new Date().getTime() - data.timeStamp;
        if (timeDifference < 600 * 1000) {
          window.location.href = data.url;
          localStorage.removeItem("RedirectReturn");
        }
      }
    },
    checkForMaintenanceMode() {
      var ts = new Date().getTime();
      this.axios.get("/maintenance?ts=" + ts).then((response) => {
        if ("maintenance" in response.data) {
          if (response.data.maintenance) {
            window.location.href = "https://kundencenter.meindienstplan.at/maintenance.html";
          }
        }
      });
    },

    listenOnChannels() {
      let view = this;
      let url = "/api/sec/socket/getChannelForCompany?companyId=" + this.$store.state.companyId;

      this.axios({
        method: "get",
        headers: { AUTHORIZATION: "Bearer " + this.$store.state.jwt },
        url: url,
      })
        .then((response) => {
          view.listeningChannels.forEach(function (entry) {
            window.Echo.leave(entry);
          });

          let channelName = "company." + response.data.socketId + "." + response.data.socketAccessToken;
          view.listeningChannels.push(channelName);

          window.Echo.channel(channelName)
            .listen(".ReplaceCompanySocketTokensEvent:replace", (e) => {
              view.listenOnChannels();
            })

            .listen(".JobReportEvent:jobCreated", (e) => {
              VueEventBus.$emit("JobReportEvent:jobCreated", e);
            })
            .listen(".JobReportEvent:jobStarted", (e) => {
              VueEventBus.$emit("JobReportEvent:jobStarted", e);
            })
            .listen(".JobReportEvent:jobEnded", (e) => {
              VueEventBus.$emit("JobReportEvent:jobEnded", e);
            })
            .listen(".JobReportEvent:jobFailed", (e) => {
              VueEventBus.$emit("JobReportEvent:jobFailed", e);
            })

            .listen(".JobEmployeeImportEvent:jobCreated", (e) => {
              VueEventBus.$emit("JobEmployeeImportEvent:jobCreated", e);
            })
            .listen(".JobEmployeeImportEvent:jobStarted", (e) => {
              VueEventBus.$emit("JobEmployeeImportEvent:jobStarted", e);
            })
            .listen(".JobEmployeeImportEvent:jobEnded", (e) => {
              VueEventBus.$emit("JobEmployeeImportEvent:jobEnded", e);
            })
            .listen(".JobEmployeeImportEvent:jobFailed", (e) => {
              VueEventBus.$emit("JobEmployeeImportEvent:jobFailed", e);
            })

            .listen(".JobEmployeeExportEvent:jobCreated", (e) => {
              VueEventBus.$emit("JobEmployeeExportEvent:jobCreated", e);
            })
            .listen(".JobEmployeeExportEvent:jobStarted", (e) => {
              VueEventBus.$emit("JobEmployeeExportEvent:jobStarted", e);
            })
            .listen(".JobEmployeeExportEvent:jobEnded", (e) => {
              VueEventBus.$emit("JobEmployeeExportEvent:jobEnded", e);
            })
            .listen(".JobEmployeeExportEvent:jobFailed", (e) => {
              VueEventBus.$emit("JobEmployeeExportEvent:jobFailed", e);
            })

            .listen(".EmployeeModifiedEvent:create", (e) => {
              VueEventBus.$emit("EmployeeModifiedEvent:create", e);
            })
            .listen(".EmployeeModifiedEvent:update", (e) => {
              VueEventBus.$emit("EmployeeModifiedEvent:update", e);
            })

            .listen(".EmployeeSocialInsuranceRegistrationEvent:register", (e) => {
              VueEventBus.$emit("EmployeeSocialInsuranceRegistrationEvent:register", e);
            })
            .listen(".EmployeeSocialInsuranceRegistrationEvent:deregister", (e) => {
              VueEventBus.$emit("EmployeeSocialInsuranceRegistrationEvent:deregister", e);
            })
            .listen(".EmployeeSocialInsuranceRegistrationEvent:uploadDocument", (e) => {
              VueEventBus.$emit("EmployeeSocialInsuranceRegistrationEvent:uploadDocument", e);
            })
            .listen(".EmployeeSocialInsuranceRegistrationEvent:confirmDocument", (e) => {
              VueEventBus.$emit("EmployeeSocialInsuranceRegistrationEvent:confirmDocument", e);
            })
            .listen(".EmployeeSocialInsuranceRegistrationEvent:delete", (e) => {
              VueEventBus.$emit("EmployeeSocialInsuranceRegistrationEvent:delete", e);
            })
            .listen(".EmployeeSocialInsuranceRegistrationEvent:batchUploadDocument", (e) => {
              VueEventBus.$emit("EmployeeSocialInsuranceRegistrationEvent:batchUploadDocument", e);
            })

            .listen(".CompanyBellEvent:newEmployeeCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:newEmployeeCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.newEmployeeCount = e.count;
              }
            })
            .listen(".CompanyBellEvent:canceledContractsCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:canceledContractsCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.canceledContractsCount = e.count;
              }
            })
            .listen(".CompanyBellEvent:socialInsuranceRegistrationCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:socialInsuranceRegistrationCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.socialInsuranceRegistrationCount = e.count;
              }
            })
            .listen(".CompanyBellEvent:minimalDatafieldsNullCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:minimalDatafieldsNullCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                if (this.minimalDatafieldsNullCount !== e.count) {
                  this.minimalDatafieldsNullCount = e.count;
                  view.checkUserNotifications();
                }
              }
            })
            .listen(".CompanyBellEvent:issuesWithImportJobsCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:issuesWithImportJobsCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.issuesWithImportJobsCount = e.count;
              }
            })
            .listen(".CompanyBellEvent:mdPersonnelIdNotUniqueCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:mdPersonnelIdNotUniqueCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.checkUserNotifications();
              }
            })
            .listen(".CompanyBellEvent:ibanNotUniqueCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:ibanNotUniqueCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.checkUserNotifications();
              }
            })
            .listen(".CompanyBellEvent:svnrNotUniqueCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:svnrNotUniqueCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.checkUserNotifications();
              }
            })
            .listen(".CompanyBellEvent:employeeExportCount", (e) => {
              VueEventBus.$emit("CompanyBellEvent:employeeExportCount", e);
              if (e != undefined && e != null && e.count != undefined && e.count != null) {
                this.employeeExportCount = e.count;
              }
            })

            .listen(".EldaCallbackEvent:update", (e) => {
              VueEventBus.$emit("EldaCallbackEvent:update", e);
            })

            .listen(".SotecDatevExportEvent:jobEnded", (e) => {
              console.log("Catched Event in Menubar: SotecDatevExportEvent:jobEnded");
              VueEventBus.$emit("SotecDatevExportEvent:jobEnded", e);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkForStartupWarning() {
      if (!this.warningShown && this.$helpers.isSafari()) {
        this.$refs["modal-startup"].show();
        this.warningShown = true;
      }
    },
    closeStartupModal() {
      this.$refs["modal-startup"].hide();
      this.warningShown = true;
    },
    closeNoPermissionsModal() {
      this.$refs["modal-nopermissions"].hide();
    },
    closeNotLoggedInModal() {
      this.$refs["modal-nopermissions"].hide();
      location.href = this.loginUrl;
    },
    closeNewEmployeesModal() {
      this.$refs["modal-newemployees"].hide();
    },
    closeCanceledContractsModal() {
      this.$refs["modal-canceledContracts"].hide();
    },
    closeSocialInsuranceRegModal() {
      this.$refs["modal-socialInsuranceRegistration"].hide();
    },
    closeMissingDataModal() {
      this.$refs["modal-missingData"].hide();
    },
    closeMdPersonnelIdDuplicatesModal() {
      this.$refs["modal-mdPersonnelIdDuplicates"].hide();
    },
    closeIbanDuplicatesModal() {
      this.$refs["modal-ibanDuplicates"].hide();
    },
    closeSvnrDuplicatesModal() {
      this.$refs["modal-svnrDuplicates"].hide();
    },
    closeMissingDocEmployeeModal(){
        this.$refs["missing-doc-employees"].hide();
    },
    closeDocumentsModal(){
        this.$refs["modal-docwarnings"].hide();
    },

    compareAlphaNum(a, b) {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;
      var aA = a.label.replace(reA, "");
      var bA = b.label.replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a.label.replace(reN, ""), 10);
        var bN = parseInt(b.label.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    },

    OpenUserMenu($event) {
      let newsCallback = this.loggedInNoCompanies
        ? null
        : () => {
            view.navigateTo("employeedatarequestoverview");
          };
      let eventLogCallback = this.loggedInNoCompanies
        ? null
        : () => {
            VueEventBus.$emit("toggle-eventlog", true);
          };
      let view = this;
      this.$helpers.OpenPopup({
        type: "menudetails",
        componentState: {
          pictureUrl: view.my_image,
          name: view.userFullname,
          News: newsCallback,
          MyAccount: () => {
            window.location = "https://account.meindienstplan.at";
          },
          EventLog: eventLogCallback,
          SignOut: view.logout,
          eventNumber: view.eventNumber,
        },
        expanded: true,
        noScroll: true,
        elRelative: $event.currentTarget,
        pointerDirection: 0,
      });
    },
    isActive(link) {
      var cur = this.$router.currentRoute;
      return cur.name === link.name;
    },
    updateOnlineUserState() {
      let entries = {};
      let hiddenList = this.activeStoreUsers.slice();
      if (!this.$store.state.isSuperUser) {
        hiddenList = hiddenList.filter((u) => !u.hidden);
      }
      let usersFiltered = hiddenList;
      let view = this;
      usersFiltered.sort((a, b) => {
        return view.$helpers.getMomentFromStringTZ(a.date) > view.$helpers.getMomentFromStringTZ(b.date) ? 1 : -1;
      });
      usersFiltered = usersFiltered.slice(0, 10);
      usersFiltered.forEach((u) => {
        entries[u.id] = {
          key: u.id,
          label: "",
          value: u.name,
          type: "text",
        };
      });

      if (hiddenList.length > 10) {
        entries["-"] = {
          key: "-",
          label: "",
          value: "...",
          type: "text",
        };
      }
      this.OnlineUserState.componentState.entries = Object.assign({}, entries);
    },
    userInfosEqual(a, b) {
      var objectsAreSame = true;
      for (var propertyName in a) {
        if (a[propertyName] !== b[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    },
    initViewMode() {
      var mode = localStorage.getItem("uimode");
      let body = document.getElementsByTagName("body")[0];
      if (mode != undefined) {
        mode = parseInt(mode);
        switch (mode) {
          case 1:
            body.classList.add("daymode");
            break;
          case 2:
            body.classList.add("nightmode");
            break;
          default:
            body.classList.add("daymode");
        }
      } else {
        mode = 1;
        body.classList.add("daymode");
        localStorage.setItem("uimode", mode);
      }
    },
    toggleDarkMode() {
      var mode = 0;
      let body = document.getElementsByTagName("body")[0];
      if (body.classList.length > 0) {
        var cur = body.classList[0];
        if (cur == "nightmode") {
          body.classList.remove("nightmode");
          body.classList.add("daymode");
          mode = 1;
        } else if (cur == "daymode") {
          body.classList.remove("daymode");
          body.classList.add("nightmode");
          mode = 2;
        }
      } else {
        mode = localStorage.getItem("uimode");
        if (mode != undefined) {
          mode = parseInt(mode);
          switch (mode) {
            case 1:
              body.classList.add("daymode");
              break;
            case 2:
              body.classList.add("nightmode");
              break;
            default:
              body.classList.add("daymode");
          }
        } else {
          mode = 1;
          body.classList.add("daymode");
        }
      }
      localStorage.setItem("uimode", mode);
      this.$store.commit("setNightMode", mode === 2);
    },
    loadPermissions: function () {
      let view = this;
      let url = "/api/sec/permission/me";
      let params = {
        companyId: view.$store.state.companyId,
      };
      return new Promise((resolve, reject) => {
        resolve(true);
      });
      /*
                return this.axios({
                    method: 'get',
                    params: params,
                    headers: {
                        'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                    },
                    url: url
                }).then(response => {
                    return new Promise((resolve, reject) => {
                        let permissions = {};
                        for (let i = 0; i<response.data.length; i++){
                            if(!permissions[response.data[i].roleId]){
                                permissions[response.data[i].roleId] = []
                            }
                            permissions[response.data[i].roleId].push(response.data[i].roleId);
                        }

                        view.$store.commit('setPermissions', {permissions: permissions});


                        resolve("SUCCESS")

                    })

                }).catch(function (error) {
                    return new Promise((resolve, reject) => {

                        reject(error)

                    })
                })*/
    },
    updateSelectedStores(stores) {
      //This method checks if there is a single store selected, if not, then select all stores
      let ids = [];
      let map = {};
      ids.push(0);
      this.storeArray.forEach((s) => {
        ids.push(s.id);
        map[s.id] = s;
      });
      let selected = [];

      if (stores != undefined) {
        stores[0].forEach((s) => {
          if (ids.includes(s)) {
            selected.push({ id: s });
          }
        });
        if (selected.length == 0 || (selected.length == 1 && selected[0].id == 0)) {
          selected = this.storeArray;
          selected.push({ id: 0 });
        }
        this.saveGlobalFilter(selected, false);
      } else {
        // select all if no saved filter found in local storage
        selected = this.storeArray;
        selected.push({ id: 0 });
        this.saveGlobalFilter(selected, false);
      }
      return new Promise((resolve, reject) => {
        resolve(selected);
      });
    },
    selectAllStores() {
      let allStores = [];
      allStores.push({ id: 0, selected: true }); // Push the not categorized store
      this.storeArray.forEach((s) => {
        allStores.push({ id: s.id, selected: true });
      });
      this.saveGlobalFilter(allStores, false); // Save the global filter in vuex, but do not push to the backend, cause everything is selected anyways - no need to save this default state
    },

    saveGlobalFilter(stores, sendToBackend) {
      let view = this;
      let filter = { companyId: view.$store.state.companyId, stores: [] };
      //Selected stores from popup
      let storesFiltered = [];
      for (let i in stores) {
        let store = { id: stores[i].id, selected: true };
        storesFiltered.push(store);
      }
      filter.stores = storesFiltered;
      view.$store.commit("setGlobalFilter", { globalFilter: filter });
      if (sendToBackend) {
        //call the POST route to the backend
        view.api.post("/sec/authuser/globalFilter", filter).then((response) => {
          view.$helpers.success("Gespeichert", "Anwendungsfilter wurde gespeichert");
        });
      }
      view.checkUserNotifications();
    },
    setExternalSoftware() {
      let view = this;
      let localExternalSoftware = 0;
      view.$helpers.GetCompanySettings().then((response) => {
        if (response.data[0].softwareId == 2) {
          view.$store.commit("setExternalSoftware", { software: 2 }); // DATEV AUSTRIA
          localExternalSoftware = 2;
        } else if (response.data[0].softwareId == 1) {
          view.$store.commit("setExternalSoftware", { software: 1 }); // DPW SAGE
          localExternalSoftware = 1;
        } else {
          view.$store.commit("setExternalSoftware", { software: 0 }); // BMD
        }
        this.$parent.addAdditionalExternalSoftwareTabs(localExternalSoftware);
      });
    },

    checkSuperUser: function () {
      let view = this;
      let url = "/api/self/isSuperuser";
      let params = {};
      return this.axios({
        method: "get",
        params: params,
        headers: { AUTHORIZATION: "Bearer " + this.$store.state.jwt },
        url: url,
      })
        .then((response) => {
          return new Promise((resolve, reject) => {
            view.$store.commit("setIsSuperUser", { isSuperUser: true });
            resolve("SUCCESS");
          });
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            view.$store.commit("setIsSuperUser", { isSuperUser: false });
            resolve("SUCCESS");
          });
        });
    },
    updateCompanies(companies) {
      let view = this;
      if (companies.length == 0) {
        this.$refs["modal-nopermissions"].show();
        view.loggedInNoCompanies = true;
      } else {
        let canUsePreselectedCompanyId = false;
        if (view.$store.state.companyId) {
          Object.values(companies).forEach((e) => {
            if (e.id == view.$store.state.companyId) {
              canUsePreselectedCompanyId = true;
            }
          });
        }
        if (view.$store.state.companyId && canUsePreselectedCompanyId) {
          console.log("selected company id: " + view.$store.state.companyId);
        } else {
          let first = companies[0];
          console.log("auto selecting company id: " + first.id);
          view.$store.commit("setCompanyId", { companyId: first.id });
          view.$store.commit("setCompanyData", { storeData: first });
        }
        view.listenOnChannels();
      }
      view.updateCompaniesDropdown(companies);
    },
    updateCompaniesDropdown(companies) {
      let view = this;
      view.processed = [];
      let selectedItem = {};
      companies.forEach((comp) => {
        let name = comp.name;

        if (name.length > 40) {
          name = name.substring(0, 40) + "...";
        }
        let entry = { label: name, id: comp.id, description: comp.description };
        if (entry.id == view.$store.state.companyId) {
          selectedItem = entry;
          view.$store.commit("setCompanyData", { companyData: entry });
        }
        view.processed.push(entry);
      });

      view.processed.sort(view.compareAlphaNum);
      view.dropdownStoreState = {
        toggled: false,
        displayedItems: [],
        input: "",
        selectedItem: selectedItem,
        items: this.processed,
        selectFirst: false,
        onceSelected: function ($event, item) {
          if (view.$store.state.companyId != item.id) {
            view.refreshedCheck = false;
            var name = view.$route.name;
            view.$store.commit("setCompanyId", { companyId: item.id });
            view.$store.commit("setCompanyData", { storeData: item });
            view.refresh(true);
            //view.$router.push({ name: name, params: { companyId: view.$store.state.companyId } });
          } else {
            console.log("selected company not changed");
          }
        },
        noBorder: true,
      };
      view.$forceUpdate();
    },

    navigateToEmployee: function (employeeId) {
      let view = this;
      //console.log(employeeId);

      view.$router.push({
        path: "../documents/employeedata",
        query: { employeeId: employeeId },
      });
      VueEventBus.$emit("changeEmployee", employeeId);
    },
    employeeSetRead: function (employeeId) {
      let view = this;
      let url = "/sec/notification/newemployees/setread";
      url = this.$helpers.appendParam("employeeId", employeeId, url, "/sec/notification/newemployees/setread");
      console.log(url);

      this.api.put(url, {}).then((response) => {
        console.log(response);
        view.refresh();
      });
    },
    employeeSetAllRead: function () {
      let view = this;
      let url = "/sec/notification/newemployees/setread/all";
      this.api.put(url, {}).then((response) => {
        view.refresh();
      });
    },
    canceledContractSetRead: function (employeeId) {
      let view = this;
      let url = "/sec/notification/canceledContracts/setread";
      url = this.$helpers.appendParam("employeeId", employeeId, url, "/sec/notification/canceledContracts/setread");
      this.api.put(url, {}).then((response) => {
        view.refresh();
      });
    },
    canceledContractSetAllRead: function () {
      let view = this;
      let url = "/sec/notification/canceledContracts/setread/all";
      this.api.put(url, {}).then((response) => {
        view.refresh();
      });
    },



    getEmployeeSyncSettingsData() {
      let view = this;
      view.employeeSyncSettingsData = {
        disabled: true,
        enableExportViaTransferService: false
      };
      this.$helpers.GetCall('/api/sec/employeesync/data').then((response) => {
        view.updateEmployeeSyncSettingsData(response.data);
      }).catch((e) => {
      });
    },
    updateEmployeeSyncSettingsData(settings) {
      let view = this;
      view.employeeSyncSettingsData = settings;
    },
    exportCSV(remoteIds) {
      let view = this;

      if (Array.isArray(remoteIds)) {
        view.exporting = 'all';
        let remoteIdArray = [];
        remoteIds.forEach((emp) => {
          remoteIdArray.push(emp.employeeId);
        });
        remoteIds = remoteIdArray;
      } else {
        view.exporting = remoteIds;
      }

      let url = '/api/sec/employeesync/export';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      url = this.$helpers.appendParam("remoteIds", remoteIds, url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        view.exporting = null;
        view.$helpers.info(
          "Info",
          "Export in das LV Tool wurde erfolgreich angestoßen."
        );
      }).catch((e) => {
        view.exporting = false;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
      });
    },
    downloadCSV(remoteIds) {
      let view = this;

      if (Array.isArray(remoteIds)) {
        view.downloading = 'all';
        let remoteIdArray = [];
        remoteIds.forEach((emp) => {
          remoteIdArray.push(emp.employeeId);
        });
        remoteIds = remoteIdArray;
      } else {
        view.downloading = remoteIds;
      }

      let url = '/api/sec/employeesync/getexport';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      url = this.$helpers.appendParam("remoteIds", remoteIds, url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        let csv = response.data.data;
        //console.log(view.$helpers.b64DecodeUnicode(csv));
        view.$helpers.forceFileDownload(view.$helpers.b64DecodeUnicode(csv), "Export.csv");
        view.downloading = null;
      }).catch((e) => {
        view.downloading = null;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
      });
    },

    showNotificationsPopup($event) {
      let view = this;
      let labels = [];
      let config = this.notificationBellConfigState;
      if (this.notificationCount > 0) {
        labels.push({
          classes: "notification-header",
          type: "groupheader",
          disabled: false,
          show: true,
          title: "Benachrichtigungen",
        });
      }
      let allHidden = Object.values(config).filter((val) => val == true).length == 0;
      if (allHidden) {
        labels.push({
          classes: "all-hidden",
          type: "block text",
          disabled: true,
          show: true,
          title: "Alle Benachrichtigungen ausgeblendet, Konfiguration prüfen",
        });
      }
      if (config.showNewEmployees && this.newEmployeeCount > 0) {
        labels.push({
          classes: "expired-docs",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-circle",
          click: ($event) => {
            view.$refs["modal-newemployees"].show();
          },
          show: true,
          title: this.newEmployeeCount + (this.newEmployeeCount == 1 ? " Neu importierter Mitarbeiter von MD" : " Neu importierte Mitarbeiter von MD"),
        });
      }
      if (config.showCanceledContracts && this.canceledContractsCount > 0) {
        labels.push({
          classes: "expired-docs",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-circle",
          click: ($event) => {
            view.$refs["modal-canceledContracts"].show();
          },
          show: true,
          title: this.canceledContractsCount + (this.canceledContractsCount == 1 ? " Arbeitsvertrag wurde beendet" : " Arbeitsverträge wurden beendet"),
        });
      }



      if (config.showSocialInsuranceRegistrations && this.socialInsuranceRegistrationCount > 0) {
        labels.push({
          classes: "expiring-docs",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-circle",
          click: ($event) => {
            this.$refs["modal-socialInsuranceRegistration"].show();
          },
          show: true,
          title:
            this.socialInsuranceRegistrationCount +
            (this.socialInsuranceRegistrationCount == 1 ? " Mitarbeiter bereit zur ÖGK Anmeldung" : " Mitarbeiter bereit zur ÖGK Anmeldung"),
        });
      }
      if (config.showMinimalDatafieldsNull && this.minimalDatafieldsNullCount > 0) {
        labels.push({
          classes: "expiring-contracts",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-triangle",
          click: ($event) => {
            this.$refs["modal-missingData"].show();
          },
          show: true,
          title: this.minimalDatafieldsNullCount + (this.minimalDatafieldsNullCount == 1 ? " Mitarbeiter benötigt Daten" : " Mitarbeiter benötigen Daten"),
        });
      }
      if (config.showIssuesWithImportJobsCount && this.issuesWithImportJobsCount > 0) {
        labels.push({
          classes: "issues-importjobs",
          type: "block",
          disabled: false,
          icon: "fa-file-import",
          click: ($event) => {
            view.navigateTo({ name: "documents", params: { tab: "EmployeeImportJobs" } });
          },
          show: true,
          title: this.issuesWithImportJobsCount + (this.issuesWithImportJobsCount == 1 ? " Problem " : " Probleme ") + "bei Mitarbeiter Import Jobs",
        });
      }
      if (config.showMdPersonnelIdNotUniqueCount && this.mdPersonnelIdNotUniqueCount > 0) {
        labels.push({
          classes: "mdpersonnelId-not-unique",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-triangle",
          click: ($event) => {
            this.$refs["modal-mdPersonnelIdDuplicates"].show();
          },
          show: true,
          title: this.mdPersonnelIdNotUniqueCount + (this.mdPersonnelIdNotUniqueCount == 1 ? " Duplikat " : " Duplikate ") + "der Personalnummer",
        });
      }
      if (config.showIbanNotUniqueCount && this.ibanNotUniqueCount > 0) {
        labels.push({
          classes: "iban-not-unique",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-triangle",
          click: ($event) => {
            this.$refs["modal-ibanDuplicates"].show();
          },
          show: true,
          title: this.ibanNotUniqueCount + (this.ibanNotUniqueCount == 1 ? " Duplikat " : " Duplikate ") + "der IBAN",
        });
      }
      if (config.showSvnrNotUniqueCount && this.svnrNotUniqueCount > 0) {
        labels.push({
          classes: "svnr-not-unique",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-triangle",
          click: ($event) => {
            this.$refs["modal-svnrDuplicates"].show();
          },
          show: true,
          title: this.svnrNotUniqueCount + (this.svnrNotUniqueCount == 1 ? " Duplikat " : " Duplikate ") + "der SV Nummer",
        });
      }
      if (config.showEmployeeExportCount && this.employeeExportCount > 0) {
        labels.push({
          classes: "ready-for-export",
          type: "block",
          disabled: false,
          icon: "fa-file-import",
          click: ($event) => {
            view.navigateTo({ name: "documents", params: { tab: "EmployeeExportOverview" } });
          },
          show: true,
          title: this.employeeExportCount + " Mitarbeiter bereit für Export",
        });
      }
      if (config.showUnconfirmedDocuments && this.unconfirmedDocuments.length > 0) {
        labels.push({
          classes: "unconfirmed-docs",
          type: "block",
          disabled: false,
          icon: "fa-file-check",
          click: ($event) => {
            this.$refs["modal-docwarnings"].show();
          },
          show: true,
          title: this.unconfirmedDocuments.length + (this.unconfirmedDocuments.length == 1 ? " unbestätigtes Dokument" : " unbestätigte Dokumente"),
        });
      }
      if (config.showExpiredDocuments && this.expiredDocuments.length > 0) {
        labels.push({
          classes: "expired-docs",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-triangle",
          click: ($event) => {
            this.$refs["modal-docwarnings"].show();
          },
          show: true,
          title: this.expiredDocuments.length + (this.expiredDocuments.length == 1 ? " abgelaufenes Dokument" : " abgelaufene Dokumente"),
        });
      }
      if (config.showExpiringDocuments && this.expiringSoonDocuments.length > 0) {
        labels.push({
          classes: "expiring-docs",
          type: "block",
          disabled: false,
          icon: "fa-exclamation-circle",
          click: ($event) => {
            this.$refs["modal-docwarnings"].show();
          },
          show: true,
          title: this.expiringSoonDocuments.length + (this.expiringSoonDocuments.length == 1 ? " bald ablaufendes Dokument" : " bald ablaufende Dokumente"),
        });
      }
      if(config.showMissingPassDocumentsForNonEU && this.missingPassDocs.length > 0){
          labels.push({
              classes: "missing-contract-docs",
              type: "block",
              disabled: false,
              icon: "fa-file-excel",
              click: ($event) => {
                  this.missingDocModalTitle = "Mitarbeiter aus Drittstaaten ohne Reisepass"
                  this.missingDocModalDescription = "Bei folgenden Mitarbeitern aus Drittstaaten ist kein Reisepass-Dokument vorhanden:"
                  this.missingDocumentModalEmployees = this.missingPassDocs;
                  this.$refs["missing-doc-employees"].show();
              },
              show: true,
              title: this.missingPassDocs.length + " Drittstaaten Mitarbeiter ohne Reisepass"
          });
      }
      if(config.showMissingWorkPermitDocumentsForNonEU && this.missingWorkPermitDocs.length > 0){
          labels.push({
              classes: "missing-contract-docs",
              type: "block",
              disabled: false,
              icon: "fa-file-excel",
              click: ($event) => {
                  this.missingDocModalTitle = "Mitarbeiter aus Drittstaaten ohne Arbeitsbewilligung"
                  this.missingDocModalDescription = "Bei folgenden Mitarbeitern aus Drittstaaten ist keine Arbeitsbewilligung vorhanden:"
                  this.missingDocumentModalEmployees = this.missingWorkPermitDocs;
                  this.$refs["missing-doc-employees"].show();
              },
              show: true,
              title: this.missingWorkPermitDocs.length + " Drittstaaten Mitarbeiter ohne Arbeitsbewilligung"
          });
      }
      if(config.showMissingResidencePermitDocumentsForNonEU && this.missingResidencePermitDocs.length > 0){
          labels.push({
              classes: "missing-contract-docs",
              type: "block",
              disabled: false,
              icon: "fa-file-excel",
              click: ($event) => {
                  this.missingDocModalTitle = "Mitarbeiter aus Drittstaaten ohne Aufenthaltsbewilligung"
                  this.missingDocModalDescription = "Bei folgenden Mitarbeitern aus Drittstaaten ist keine Aufenthaltsbewilligung vorhanden:"
                  this.missingDocumentModalEmployees = this.missingResidencePermitDocs;
                  this.$refs["missing-doc-employees"].show();
              },
              show: true,
              title: this.missingResidencePermitDocs.length + " Drittstaaten Mitarbeiter ohne Aufenthaltsbewilligung"
          });
      }
      // check if at least 1 German store is selected
      let country = this.storeArray != null && this.storeArray.find(s => s.country == 2) ? 2 : 1;
      if(config.showMissingEducationPermitDocumentsForNonEU && this.missingEducationPermitDocs.length > 0  &&  country == 2){
          labels.push({
              classes: "missing-contract-docs",
              type: "block",
              disabled: false,
              icon: "fa-file-excel",
              click: ($event) => {
                  this.missingDocModalTitle = "Mitarbeiter aus Drittstaaten ohne Schul-/Studium-Bescheinigung"
                  this.missingDocModalDescription = "Bei folgenden Mitarbeitern aus Drittstaaten ist keine Schul-/Studium-Bescheinigung vorhanden:"
                  this.missingDocumentModalEmployees = this.missingEducationPermitDocs;
                  this.$refs["missing-doc-employees"].show();
              },
              show: true,
              title: this.missingEducationPermitDocs.length + " Drittstaaten Mitarbeiter ohne Schul-/Studium-Bescheinigung"
          });
      }
      labels.push({
        type: "border-top",
      });
      labels.push({
        classes: "notification-settings",
        type: "groupheader",
        disabled: false,
        show: true,
        title: "Einstellungen",
      });
      labels.push({
        classes: "notification-settings",
        type: "block",
        disabled: false,
        icon: "fa-cogs",
        click: ($event) => {
          this.showNotificationBellConfig();
        },
        show: true,
        title: "Benachrichtigungen konfigurieren",
      });

      this.$helpers.OpenPopup({
        type: "listlabel",
        componentState: {
          labels: labels,
        },
        listStyle: true,
        noScroll: true,
        pointerDirection: 0,
        elRelative: $event.currentTarget,
        pointerOffset: -15,
      });
    },
    showGlobalFilterPopup($event) {
      let view = this;
      let dataEntries = [];
      console.log("GLOBAL FILTER");
      console.log(this.globalFilter);
      dataEntries.push({
        label: "",
        key: "number",
        hidden: false,
        type: "output",
        value: "Kostenstellen",
      });
      dataEntries.push({
        label: "Nicht zugeordnet",
        key: "noStore",
        hidden: false,
        type: "bool",
        value:
          this.globalFilter.stores.filter((elem) => {
            return elem.id == 0;
          }).length > 0,
      });
      this.storeArray.forEach((s) => {
        let label = s.name;
        if (s.description != undefined && s.description != "") label += " (" + s.description + ")";
        if (s.name && s.name.length > 0) {
          dataEntries.push({
            label: label,
            key: s.id,
            hidden: false,
            type: "bool",
            value:
              this.globalFilter.stores.filter((elem) => {
                return elem.id == s.id;
              }).length > 0,
          });
        }
      });
      this.$helpers.OpenPopup({
        elRelative: $event.currentTarget,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          selectCallback: function (result, datafill) {
            datafill.$parent.locked = false;
            let selected = [];
            if ("noStore" in result && result["noStore"]) selected.push({ id: 0 });
            view.storeArray.forEach((s) => {
              if (s.id in result && result[s.id]) selected.push(s);
            });
            view.saveGlobalFilter(selected, true);
          },
        },
        header: "Anwendungsfilter",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
        locked: false,
      });
    },
    showNotificationBellConfig() {
      let view = this;
      let config = this.notificationBellConfigState;
      let description = "Welche Benachrichtigungen sollen angezeigt werden?<br/>";
      let dataEntries = [];

      dataEntries.push({
        label: "Neu importierte Mitarbeiter",
        key: "showNewEmployees",
        hidden: false,
        type: "bool",
        value: config.showNewEmployees,
      });
      dataEntries.push({
        label: "Beendete Arbeitsverträge",
        key: "showCanceledContracts",
        hidden: false,
        type: "bool",
        value: config.showCanceledContracts,
      });
      dataEntries.push({
        label: "Mitarbeiter bereit zur ÖGK Anmeldung",
        key: "showSocialInsuranceRegistrations",
        hidden: false,
        type: "bool",
        value: config.showSocialInsuranceRegistrations,
      });
      dataEntries.push({
        label: "Fehlende Mitarbeiter Daten",
        key: "showMinimalDatafieldsNull",
        hidden: false,
        type: "bool",
        value: config.showMinimalDatafieldsNull,
      });
      dataEntries.push({
        label: "Probleme bei Import Jobs",
        key: "showIssuesWithImportJobsCount",
        hidden: false,
        type: "bool",
        value: config.showIssuesWithImportJobsCount,
      });
      dataEntries.push({
        label: "Duplikate der Personalnummer",
        key: "showMdPersonnelIdNotUniqueCount",
        hidden: false,
        type: "bool",
        value: config.showMdPersonnelIdNotUniqueCount,
      });
      dataEntries.push({
        label: "Duplikate der IBAN",
        key: "showIbanNotUniqueCount",
        hidden: false,
        type: "bool",
        value: config.showIbanNotUniqueCount,
      });
      dataEntries.push({
        label: "Duplikate der SV Nummer",
        key: "showSvnrNotUniqueCount",
        hidden: false,
        type: "bool",
        value: config.showSvnrNotUniqueCount,
      });
      // dataEntries.push({
      //   label: "Mitarbeiter bereit für Export",
      //   key: "showEmployeeExportCount",
      //   hidden: false,
      //   type: "bool",
      //   value: config.showEmployeeExportCount,
      // });

      dataEntries.push({label: "Nicht bestätigte Dokumente", key: 'showUnconfirmedDocuments', hidden: false, type: 'bool', value: config.showUnconfirmedDocuments});
      dataEntries.push({label: "Abgelaufene Dokumente", key: 'showExpiredDocuments', hidden: false, type: 'bool', value: config.showExpiredDocuments});
      dataEntries.push({label: "Bald ablaufende Dokumente", key: 'showExpiringDocuments', hidden: false, type: 'bool', value: config.showExpiringDocuments});
      dataEntries.push({label: "Warnung für bald ablaufende Dokumente (Tage)", key: 'documentExpiryLimit', hidden: false,
        type: 'slider', value: config.documentExpiryLimit, slider: {min: 1, max: 90, value: config.documentExpiryLimit, step: 1}
      });
      dataEntries.push({label: "Fehlende Reisepass Dokumente für Mitarbeiter aus Drittstaaten", key: 'showMissingPassDocumentsForNonEU', hidden: false, type: 'bool', value: config.showMissingPassDocumentsForNonEU});
      dataEntries.push({label: "Fehlende Arbeitsbewilligung Dokumente für Mitarbeiter aus Drittstaaten", key: 'showMissingWorkPermitDocumentsForNonEU', hidden: false, type: 'bool', value: config.showMissingWorkPermitDocumentsForNonEU});
      dataEntries.push({label: "Fehlende Aufenthaltsbewilligung Dokumente für Mitarbeiter aus Drittstaaten", key: 'showMissingResidencePermitDocumentsForNonEU', hidden: false, type: 'bool', value: config.showMissingResidencePermitDocumentsForNonEU});
      dataEntries.push({label: "Fehlende Schul-/Studium-Bescheinigung Dokumente für Mitarbeiter aus Drittstaaten", key: 'showMissingEducationPermitDocumentsForNonEU', hidden: false, type: 'bool', value: config.showMissingEducationPermitDocumentsForNonEU});


      view.$helpers.OpenPopup({
        type: "datafill",
        componentState: {
          entries: dataEntries,
          description: description,
          selectCallback: function (data) {
            for (let key in data) {
              if (data[key] != config[key]) {
                view.$store.commit("setNotificationBellConfigStateValue", {
                  key: key,
                  value: data[key],
                });
              }
            }
            console.log("Notification bell settings updated");
            console.log(view.notificationBellConfigState);
            view.checkUserNotifications();
          },
        },
        header: "Benachrichtigungs Einstellungen",
        expanded: true,
        width: "900px",
        centered: true,
      });
    },

    loadUserInfo: function () {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
      /* let view = this;
                let url = '/api/semi/user/me';
                this.axios({
                    method: 'get',
                    headers: {
                        'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                    },
                    url: url
                }).then(response => {
                    let userInfo = {
                        firstname: response.data.firstname,
                        lastname: response.data.lastname,
                        image_url:  response.data.profileimage ? response.data.profileimage.url : null,
                        id: response.data.id
                    };
                    view.$store.commit("setUserInfo", {userInfo: userInfo});

                    if(response.data.profileimage){
                        view.my_image = view.userInfo.image_url;
                    }
                }).catch(function (error) {
                    console.log(error);
                }); */
    },
    Init: function (styles) {
      this.styles = styles;
    },
    ToggleCollapse: function ($event) {
      var event = $event;
      event.currentTarget.parentElement.classList.toggle("show");
    },
    OpenNavMenu($event) {
      let view = this;
      let labels = [];
      this.computedIconsMain.forEach((icon) => {
        labels.push({
          text: icon.label,
          type: "block",
          icon: icon.icon,
          click: () => {
            window.open(icon.externalLink, "_blank");
          },
        });
      });
      this.$helpers.OpenPopup({
        type: "listlabel",
        componentState: {
          labels: labels,
        },
        listStyle: true,
        noScroll: true,
        pointerDirection: 0,
        elRelative: $event.currentTarget,
        pointerOffset: -15,
      });
    },
    ToggleDropDown: function ($event, selection, offsetx, offsety, pointerOffset) {
      if (!selection || selection.length === 0) return;
      var event = $event;
      var box = event.currentTarget.getBoundingClientRect();
      var dropdowns = this.dropdowns;
      if (selection === this.iconsNav && window.innerWidth <= 750) {
        selection = this.iconsNavCollapsed;
      }
      this.dropdowns.push({
        items: selection,
        el: event.currentTarget,
        remainAfterSelect: true,
        toggled: true,
        offsetx: offsetx,
        offsety: offsety,
        posx: box.left + offsetx,
        posy: box.bottom + offsety,
        pointerOffset: pointerOffset,
        onceSelected: function ($eventInner, item, dropdown) {
          if (item.items && item.items.length > 0) {
            var eventInner = $eventInner;
            var boxInner = eventInner.currentTarget.getBoundingClientRect();
            var temp = {
              items: item.items,
              el: eventInner.currentTarget,
              offsetx: offsetx + 130,
              offsety: offsety - 30,
              posx: box.left + offsetx + 130,
              toggled: true,
              posy: boxInner.bottom - 30,
              pointerDirection: 3,
              pointerOffset: pointerOffset,
              parentCallback: function () {
                dropdown.toggled = false;
              },
            };
            dropdowns.push(temp);
          }
        },
      });
    },
    ToggleDropDownMenuNav: function ($event, selection, offsetx, offsety) {
      var event = $event;
      var box = event.currentTarget.getBoundingClientRect();
      var dropdowns = this.dropdowns;
      var temp = {
        el: event.currentTarget,
        offsetx: offsetx,
        offsety: offsety,
        posx: box.left + offsetx,
        posy: box.bottom + offsety,
        items: selection.items,
        toggled: true,
        onceSelected: function ($eventInner, item, dropdown) {
          dropdowns = dropdowns.filter((d) => d !== temp);
        },
      };
      this.dropdowns.push(temp);
      /*this.$refs.dropdown.Init(selection.items, {
                    el: event.currentTarget,
                    posx: box.left + offsetx,
                    posy : box.bottom + offsety,
                });*/
    },
    ToggleDropDownUser: function ($event, user, offsetx, offsety) {
      var event = $event;
      var box = event.currentTarget.getBoundingClientRect();
      this.$refs.dropdown.posx = box.left + offsetx;
      this.$refs.dropdown.posy = box.bottom + offsety;
      this.$refs.dropdown.items = user.items;
      this.$refs.dropdown.onceSelected = function () {
        this.$forceUpdate();
      };
      this.$refs.dropdown.toggle();
    },
    ToggleDropDownLogin: function ($event, user, offsetx, offsety) {
      var event = $event;
      var box = event.currentTarget.getBoundingClientRect();
      this.$refs.dropdownlogin.posx = box.left + offsetx;
      this.$refs.dropdownlogin.posy = box.bottom + offsety;
      this.$refs.dropdownlogin.onceSelected = function () {
        this.$forceUpdate();
      };
      this.$refs.dropdownlogin.toggle();
    },
    navigateTo(destination) {
      this.$router.push(destination);
      var cur = this.$router.currentRoute;
      if (cur.name === destination) return;
      //this.$router.replace(destination)
    },
    getJwtParam() {
      let url = window.location.href;
      if (url.includes("jwt=")) {
        let subIndex = url.indexOf("jwt=") + 4;
        return url.substring(subIndex);
      }
      return null;
    },
    getQueryStringValue: function (key) {
      let url = new URL(window.location.href);
      //console.log(window.location.href);
      let searchParams = new URLSearchParams(url.search);
      searchParams.forEach((p) => {
        console.log(p);
      });
      return searchParams.get(key);
      //return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
    },
    supportsHTML5Storage: function () {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    },

    login: function () {
      let view = this;
      if (!this.$helpers.isBlank(view.$store.state.jwt)) {
        let url = "/api/login";

        this.axios({
          method: "get",
          headers: {
            AUTHORIZATION: "Bearer " + view.$store.state.jwt,
          },
          url: url,
        })
          .then((response) => {
            //view.$store.commit('setJWT', {jwt: jwt});
            let browserUrl = window.location.href;
            if (browserUrl.includes("jwt=")) {
              console.log("JWT IS STILL IN THE URL REMOVE IT");
              window.history.pushState("", "ML - MeineLohnabrechnung", "/");
            }
            view.SetRedirectReturn();
            view.initAnalytics();
            view.refresh(true);
          })
          .catch(function (error) {
            view.$store.commit("clearJWT");
            if (view.authframe != null) {
              console.log("clearing jwt in auth frame");
              view.authframe.postMessage(JSON.stringify({ key: "md_token", method: "remove" }), "*");
            }
            if (view.$route.name != "home") {
              console.log("going home");
              view.navigateTo("");
            }
            /* view.$helpers.error('Fehler', 'Login fehlgeschlagen'); */
            console.log("LOGIN FEHLGESCHLAGEN");
            view.PrepareRedirectReturn();
            this.$refs["modal-notloggedin"].show();
            //window.location = view.loginUrl;
          });
      } else {
        console.log("FEHLER LOGIN");
        view.PrepareRedirectReturn();
        this.$refs["modal-notloggedin"].show();
        //window.location = view.loginUrl;
      }
    },
    logout: function () {
      console.log("LOGOUT");
      localStorage.removeItem("md_token");
      localStorage.removeItem("selected_store");
      localStorage.removeItem("selected_company");

      let sub = this.getSubDomain();
      if (sub === "staging") {
        window.location = "https://account.meindienstplan.at/#/logout?redirect=stagingml";
      } else if (sub === "development") {
        window.location = "https://account.meindienstplan.at/#/logout?redirect=devml";
      } else {
        window.location = "https://account.meindienstplan.at/#/logout?redirect=ml";
      }
    },

    refresh: function (force = false) {
      let view = this;

      console.log("REFRESH MENUBAR");

      this.updateMdReleaseVersion();
      view.setExternalSoftware();
      this.$helpers.GetUiDataMap(true);
      this.$helpers.LoadEnums(true);

      this.$emit("menubar-loading");
      view.$helpers
        .GetCompanyArray(force)
        .then((companies) => {
          view.updateCompanies(companies);
          if (!view.loggedInNoCompanies) {
            view.checkUserNotifications();
            view.getEmployeeSyncSettingsData();
            view.$helpers.GetStoresArray(force).then((stores) => {
              view.api.get("/sec/authuser/globalFilter").then((response) => {
                console.log("Response from global filter which is stored in the backend");
                console.log(response);
                if (response.data && response.data.stores && Array.isArray(response.data.stores)) {
                  console.log("we got a valid filter back");
                  let selectedStores = [];
                  response.data.stores.forEach((s) => {
                    if (typeof s.id !== "undefined" && s.selected) {
                      selectedStores.push({ id: s.id, selected: s.selected });
                    }
                  });
                  this.saveGlobalFilter(selectedStores, false);
                } else {
                  console.log("no filter found, thus select all stores");
                  // Undefined, there is no global filter set yet => select all stores / and no need to save the filter
                  view.selectAllStores();
                }
              });
              view.$helpers.GetSelectedStores().then((selectedStores) => {
                //view.updateSelectedStores(selectedStores);
                view.$emit("menubar-ready");
              });
            });
            return view.$helpers.GetCompanySettings();
          }
        })
        .then((success) => {
          view.companyData = success.data[0];
          view.refreshedCheck = true;
          return view.loadPermissions();
        })
        .then((success) => {
          return view.checkSuperUser();
        })
        .then((success) => {
          view.$store.commit("setLoggedIn", { loggedIn: true });
          view.$helpers.LoadMaps();

          //view.navigateTo('companymain');
        })
        .catch((error) => {
          //Something went wrong => maybe user doesnt have permission for selected store
          // => clear localstorage

          localStorage.removeItem("selected_store");
          localStorage.removeItem("selected_company");
          view.$store.commit("setCompanyId", { companyId: null });
          view.checkForStartupWarning();
          //TODO: navigate to a startpage but iwthout auto login
        });
    },
    getCompanyNotifications: function () {
      let view = this;
      let url = "/api/sec/notification/company";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/notification/company");

      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    getNewEmployees: function () {
      let view = this;
      let url = "/api/sec/notification/newemployees";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/notification/newemployees");

      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    getCanceledContracts: function () {
      let view = this;
      let url = "/api/sec/notification/canceledContracts";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/notification/canceledContracts");

      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    loadEmployees: function (force = false, employeeIdToSelect = null) {
      let view = this;
      this.$helpers.GetEmployeeMap()
        .then((response) => {
          view.employeeArray = Object.values(response);

          // split hidden employees from employeeArray
          view.allEmployeesArray = JSON.parse(JSON.stringify(view.employeeArray));

          // filter regular employeeArray
          view.employeeArray = view.employeeArray.filter(emp => !emp.isHidden);
          if (view.globalFilter.stores) {
            view.employeeArray = view.employeeArray.filter(emp => view.globalFilter.stores.find(s => s.id === emp.storeId) !== undefined);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getSubDomain: function () {
      let full = window.location.host;
      //window.location.host is subdomain.domain.com
      let parts = full.split(".");
      return parts[0];
    },
    updateMdReleaseVersion: function () {
      let view = this;
      let url = "/api/self/hasReadLatestChangelog";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/self/hasReadLatestChangelog");
      this.axios({
        method: "get",
        headers: { AUTHORIZATION: "Bearer " + this.$store.state.jwt },
        url: url,
      }).then((response) => {
        if (!response.data.hasReadLatestChangelog && response.data.acceptsPopup) {
          let changelogUrl = "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/2113339393?src=675099026";
          view.$helpers.OpenAlert({
            header: "ML - Update Information",
            description:
              "MEINELOHNABRECHNUNG wurde am <strong>" +
              response.data.releaseDate +
              "</strong> auf Version <strong>" +
              response.data.latestVersion +
              "</strong><br/> aktualisiert.<br/><br/>Im Changelog können Sie sehen welche Funktionen eingebaut wurden.<br/>",
            disableAccept: true,
            cancelLabel: "Schließen",
            externalLabel: "Changelog öffnen",
            externalUrl: changelogUrl,
            enableExternal: true,
          });

          let url = "/api/self/confirmLatestChangelog";
          this.axios({
            method: "put",
            headers: { AUTHORIZATION: "Bearer " + this.$store.state.jwt },
            url: url,
          }).then((response) => {});
        }
      });
    },
    openAbout() {
      let view = this;
      this.$helpers.OpenPopup({
        type: "aboutpopup",
        componentState: {
          mdrelease: view.mdrelease,
          mdversion: view.mdversion,
        },
        centered: true,
        expanded: true,
      });
    },

    employeesWithDuplicateMdPersonnelIds() {
      let view = this;
      let emps = view.allEmployeesArray.filter(e => view.mdPersonnelIdDuplicates.includes(e.mdPersonnelId));
      emps = emps.sort((a,b) => {
        if (parseInt(a.mdPersonnelId) > parseInt(b.mdPersonnelId)) {
          return 1;
        } else if (parseInt(b.mdPersonnelId) > parseInt(a.mdPersonnelId)) {
          return -1;
        } else {
          return 0;
        }
      });
      emps.forEach(e => {
        let employee = view.allEmployeesArray.find(emp => emp.id === e.id);
        e.issue = false;
        e.issueText = "";
        if (employee && employee.isHidden) {
          e.issue = true;
          e.issueText += " (Versteckt)";
        }
        if (employee && view.globalFilter.stores && view.globalFilter.stores.find(s => s.id === employee.storeId) === undefined) {
          e.issue = true;
          e.issueText += " (Store ausgeblendet)";
        }
      });
      return emps;
    },

    employeesWithDuplicateIbans() {
      let view = this;
      let emps = view.allEmployeesArray.filter(e => view.ibanDuplicates.includes(e.iban ? e.iban.replace(/\s/g, '') : ""));
      emps = emps.sort((a,b) => {
        if (a.iban > b.iban) {
          return 1;
        } else if (b.iban > a.iban) {
          return -1;
        } else if (a.lastName > b.lastName) {
          return 1;
        } else if (a.lastName < b.lastName) {
          return -1;
        } else if (a.firstName > b.firstName) {
          return 1;
        } else if (a.firstName < b.firstName) {
          return -1;
        } else {
          return 0;
        }
      });
      emps.forEach(e => {
        let employee = view.allEmployeesArray.find(emp => emp.id === e.id);
        e.issue = false;
        e.issueText = "";
        if (employee && employee.isHidden) {
          e.issue = true;
          e.issueText += " (Versteckt)";
        }
        if (employee && view.globalFilter.stores && view.globalFilter.stores.find(s => s.id === employee.storeId) === undefined) {
          e.issue = true;
          e.issueText += " (Store ausgeblendet)";
        }
      });
      return emps;
    },

    employeesWithDuplicateSvnrs() {
      let view = this;
      let emps = view.allEmployeesArray.filter(e => view.svnrDuplicates.includes(e.socialsecurity ? e.socialsecurity.replace(/\s/g, '') : ""));
      emps = emps.sort((a,b) => {
        if (a.socialsecurity > b.socialsecurity) {
          return 1;
        } else if (b.socialsecurity > a.socialsecurity) {
          return -1;
        } else if (a.lastName > b.lastName) {
          return 1;
        } else if (a.lastName < b.lastName) {
          return -1;
        } else if (a.firstName > b.firstName) {
          return 1;
        } else if (a.firstName < b.firstName) {
          return -1;
        } else {
          return 0;
        }
      });
      emps.forEach(e => {
        let employee = view.allEmployeesArray.find(emp => emp.id === e.id);
        e.issue = false;
        e.issueText = "";
        if (employee && employee.isHidden) {
          e.issue = true;
          e.issueText += " (Versteckt)";
        }
        if (employee && view.globalFilter.stores && view.globalFilter.stores.find(s => s.id === employee.storeId) === undefined) {
          e.issue = true;
          e.issueText += " (Store ausgeblendet)";
        }
      });
      return emps;
    },

    loadDocumentNotificationData(){
      this.employeeEmployeeDocumentMap = {};
      this.$helpers.GetEmployeeDocumentsMap().then(fmap => {
        let map = {};
        Object.values(fmap).forEach(f => {
          if(!map[f.employeeId]){
            map[f.employeeId] = [];
          }
          f.creationDate = this.$helpers.getMomentFromStringUTC(f.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSSS").tz("Europe/Vienna").format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
          f.validUntil = this.$helpers.getMomentFromStringUTC(f.validUntil, "YYYY-MM-DDTHH:mm:ss.SSSSSS").tz("Europe/Vienna").format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
          map[f.employeeId].push(f);
        })
        this.employeeEmployeeDocumentMap = map;
      });
    },
    checkDocuments(){
      let view = this;
      let expiringSoon = [];
      let expired = [];
      let unconfirmed = [];
      let config = this.notificationBellConfigState;
      let now = this.$helpers.getMoment();
      let soonInterval = 56;
      if(config.documentExpiryLimit){
        soonInterval = Math.max(1, config.documentExpiryLimit);
      }
      let soonDate = now.clone().add(soonInterval, "days");

      this.employeeArray.forEach(e => {
        let files = this.employeeEmployeeDocumentMap[e.id] ? this.employeeEmployeeDocumentMap[e.id] : [];
        files.forEach((file) => {
          if(file.validUntil != null && !file.validUntil.startsWith("Invalid")){
            let validUntil = this.$helpers.getMomentFromStringUTC(file.validUntil);
            if(validUntil < now){
              expired.push(file);
            } else if(validUntil < soonDate){
              expiringSoon.push(file);
            }
          }
          if(!file.confirmed){
            unconfirmed.push(file)
          }
        });
      })

      let hasWarnings = (expired.length + expiringSoon.length) > 0;
      expired = expired.sort((a, b) => {
        let aDate = view.$helpers.getMomentFromStringTZ(a.validUntil);
        let bDate = view.$helpers.getMomentFromStringTZ(b.validUntil);
        return aDate.unix() - bDate.unix();
      })

      expiringSoon = expiringSoon.sort((a, b) => {
        let aDate = view.$helpers.getMomentFromStringTZ(a.validUntil);
        let bDate = view.$helpers.getMomentFromStringTZ(b.validUntil);
        return aDate.unix() - bDate.unix();
      })
      unconfirmed = unconfirmed.sort((a, b) => {
        let aDate = view.$helpers.getMomentFromStringTZ(a.creationDate);
        let bDate = view.$helpers.getMomentFromStringTZ(b.creationDate);
        return aDate.unix() - bDate.unix();
      })
      this.expiredDocuments = expired;
      this.expiringSoonDocuments = expiringSoon;
      this.unconfirmedDocuments = unconfirmed;
    },
    checkNonEUWorkerDocuments(){

      let missingPass = [];
      let missingWorkPermit = [];
      let missingResidencePermit = [];
      let missingEducationPermit = [];

      this.employeeArray.filter(e => {
        return e.citizenTypeId != null && (e.citizenTypeId == 2 || e.citizenTypeId == 5) && !e.dummy;
      }).sort((a, b) => {
        let aName = a.lastName + " " + a.firstName;
        let bName = b.lastName + " " + b.firstName;
        return aName.localeCompare(bName);
      }).forEach(e => {
        if(!this.employeeEmployeeDocumentMap[e.id]){
          if(e.citizenTypeId != 5){
            missingPass.push(e.id);
          }

          missingWorkPermit.push(e.id);
          missingResidencePermit.push(e.id);
          missingEducationPermit.push(e.id);
        } else {
          // this.employeeEmployeeDocumentMap[e.id].forEach(f => {
          //   console.log(f.documentType);
          // })
          if(e.citizenTypeId != 5 && this.employeeEmployeeDocumentMap[e.id].filter(f => f.documentType == 'PASSPORT' || f.documentType == 'ASYLUMPASS').length == 0){
            missingPass.push(e.id);
          }
          if(this.employeeEmployeeDocumentMap[e.id].filter(f => f.documentType == 'WORKPERMIT' || f.documentType == 'RESIDENCEWORKPERMIT' || f.documentType == 'ASYLUMPASS').length == 0){
            missingWorkPermit.push(e.id);
          }
          if(this.employeeEmployeeDocumentMap[e.id].filter(f => f.documentType == 'RESIDENCEPERMIT' || f.documentType == 'RESIDENCEWORKPERMIT' || f.documentType == 'ASYLUMPASS').length == 0){
            missingResidencePermit.push(e.id);
          }
          if(this.employeeEmployeeDocumentMap[e.id].filter(f => f.documentType == 'EDUCATIONCERTIFICATE').length == 0){
            missingEducationPermit.push(e.id);
          }

        }
      })
      this.missingPassDocs = missingPass;
      this.missingWorkPermitDocs = missingWorkPermit;
      this.missingResidencePermitDocs = missingResidencePermit;
      this.missingEducationPermitDocs = missingEducationPermit;
    },

    checkUserNotifications() {
      let view = this;
      let promises = [];
      promises.push(view.loadDocumentNotificationData());
      promises.push(view.getCompanyNotifications().then((response) => {
        //this.newEmployeeCount = response.data.newEmployeeCount;
        view.canceledContractsCount = response.data.canceledContractsCount;
        view.socialInsuranceRegistrationCount = response.data.socialInsuranceRegistration;
        view.minimalDatafieldsNullCount = response.data.minimalDatafieldsNull;
        view.issuesWithImportJobsCount = response.data.issuesWithImportJobsCount;
        view.mdPersonnelIdNotUniqueCount = response.data.mdPersonnelIdNotUniqueCount;
        view.ibanNotUniqueCount = response.data.ibanNotUniqueCount;
        view.svnrNotUniqueCount = response.data.svnrNotUniqueCount;
        view.employeeExportCount = response.data.employeeExportCount;
      }));
      promises.push(view.getNewEmployees().then((response) => {
          view.newEmployees = response.data.data;
      }));
      promises.push(view.getCanceledContracts().then((response) => {
          view.canceledContracts = response.data.data;
      }));
      promises.push(view.loadEmployees());

      Promise.all(promises).then(() => {
          this.checkDocuments();
          this.checkNonEUWorkerDocuments();

          view.newEmployees.forEach((e) => {
            let emp = view.employeeArray.find(emp => emp.id == e.employeeId);
            if (emp) {
              e.firstName = emp.firstName;
              e.lastName = emp.lastName;
            }
          });
          view.newEmployees = view.newEmployees.filter(e => e.firstName != undefined);
          view.newEmployeeCount = view.newEmployees.length;

          view.canceledContracts.forEach((e) => {
            let emp = view.employeeArray.find(emp => emp.id == e.employeeId);
            if (emp) {
              e.firstName = emp.firstName;
              e.lastName = emp.lastName;
            }
          });
          view.canceledContracts = view.canceledContracts.filter(e => e.firstName != undefined);
          view.canceledContractsCount = view.canceledContracts.length;

          view.employeesWithoutSIR = [];
          view.socialInsuranceRegistrationCount = 0;
          view.employeesWithMinimalDataMissing = [];
          view.minimalDatafieldsNullCount = 0;

          for (let key in view.employeeArray) {
            if (parseInt(view.employeeArray[key].socialInsuranceRegistered) == 0) {
              let e = {
                employeeId: view.employeeArray[key].id,
                firstName: view.employeeArray[key].firstName,
                lastName: view.employeeArray[key].lastName,
              };
              view.employeesWithoutSIR.push(e);
              view.socialInsuranceRegistrationCount++;
            }

            if (parseInt(view.employeeArray[key].minimalDatafieldsNotNull) == 0) {
              let e = {
                employeeId: view.employeeArray[key].id,
                firstName: view.employeeArray[key].firstName,
                lastName: view.employeeArray[key].lastName,
              };
              view.employeesWithMinimalDataMissing.push(e);
              view.minimalDatafieldsNullCount++;
            }
          }

          view.mdPersonnelIdNotUniqueCount = 0;
          let mdPersonnelIds = [];
          view.mdPersonnelIdDuplicates = [];

          for(let key in view.allEmployeesArray) {
            if (view.allEmployeesArray[key].mdPersonnelId) {
              if (!mdPersonnelIds.includes(view.allEmployeesArray[key].mdPersonnelId)) {
                mdPersonnelIds.push(view.allEmployeesArray[key].mdPersonnelId);
              } else if (!view.mdPersonnelIdDuplicates.includes(view.allEmployeesArray[key].mdPersonnelId)) {
                view.mdPersonnelIdDuplicates.push(view.allEmployeesArray[key].mdPersonnelId);
              } else {
                // do nothing
              }
            }
          }
          view.mdPersonnelIdNotUniqueCount = view.mdPersonnelIdDuplicates.length;

          view.ibanNotUniqueCount = 0;
          let ibans = [];
          view.ibanDuplicates = [];

          for(let key in view.allEmployeesArray) {
            if (view.allEmployeesArray[key].iban) {
              let iban = view.allEmployeesArray[key].iban.replace(/\s/g, '');
              if (!ibans.includes(iban)) {
                ibans.push(iban);
              } else if (!view.ibanDuplicates.includes(iban)) {
                view.ibanDuplicates.push(iban);
              } else {
                // do nothing
              }
            }
          }
          view.ibanNotUniqueCount = view.ibanDuplicates.length;

          view.svnrNotUniqueCount = 0;
          let svnrs = [];
          view.svnrDuplicates = [];

          for(let key in view.allEmployeesArray) {
            if (view.allEmployeesArray[key].socialsecurity) {
              let socialsecurity = view.allEmployeesArray[key].socialsecurity.replace(/\s/g, '');
              if (!svnrs.includes(socialsecurity)) {
                svnrs.push(socialsecurity);
              } else if (!view.svnrDuplicates.includes(socialsecurity)) {
                view.svnrDuplicates.push(socialsecurity);
              } else {
                // do nothing
              }
            }
          }
          view.svnrNotUniqueCount = view.svnrDuplicates.length;
          view.$forceUpdate();
      });
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-bar {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 55px;
  background: var(--menu-bar);
  z-index: 1000;
  padding: 5px;
}

.menu-bar > * {
  float: left;
}

.icons-main {
  border-radius: 5px;
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.icons-main .icon {
  border-radius: 5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 10px 0px 0px 10px;
  display: inline-block;
}

.icons-main.fixed {
  padding: 10px;
  cursor: default;
}

.icons-main.fixed .icon-visibility-container {
  cursor: default;
}

.icons-main.fixed .icon,
.icons-main.fixed .fas {
  cursor: default;
  display: none;
}

.icons-main.fixed .icon:first-child {
  display: inline-block;
}

.icons-nav,
.icons-left {
  position: absolute;
  left: 60px;
  bottom: 15px;
  height: 25px;
  margin: auto;
  /* width: 340px; */
  text-align: left;
}

.icons-nav > .button:nth-last-child(2) {
  margin: 0px 23px 0px 10px;
}

.icons-nav > .button:nth-last-child(1):before {
  position: absolute;
  content: "";
  height: 25px;
  width: 1px;
  top: 0;
  bottom: 0;
  left: -15px;
  margin: auto;
  background: none;
  border-right: 1px solid var(--contrast-2);
  border-radius: 0;
  pointer-events: none;
}

.icons-nav > .button:nth-last-child(2):after {
  position: absolute;
  content: "";
  height: 25px;
  width: 1px;
  top: 0;
  bottom: 0;
  right: -15px;
  margin: auto;
  background: none;
  border-right: 1px solid var(--contrast-2);
  border-radius: 0;
  pointer-events: none;
}
.icons-left {
  left: 285px !important;
}
.icons-left .fa-cog {
  margin-right: 10px;
  transform: translateY(1px);
  float: left;
  margin-top: 4px;
}
.icons-left .dividerh {
  display: none;
}

.icons-selection {
  position: absolute;
  /*left: 695px;*/
  left: 583px;
  margin: auto;
  bottom: 15px;
  height: 25px;
  width: 270px;
  padding-top: 5px;
}

.icons-selection > * {
  float: left;
  margin-right: 5px;
}

.icons-selection > .label {
  margin-top: -2px;
}

.icons-selection > .fas {
  margin-right: 5px;
  margin-left: 1px;
  font-size: 16px;
}

.icons-selection .dropdown-search {
  width: 180px;
  margin-top: -6px;
  margin-left: 8px;
  position: relative;
}

.icon-main-mz,
.icon-main-mp,
.icon-main-md {
  background: url(/logo.png);
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.nightmode .icon-app-switch {
  background: url(/img/btn_app_switch_light.png);
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.daymode .icon-app-switch {
  background: url(/img/btn_app_switch.png);
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.icon-app-switch {
  transition: 0.2s;
}

.icon-app-switch:hover {
  filter: brightness(300%);
}

.login {
  position: absolute;
  font-size: 14px;
  top: 15px;
  font-weight: 600;
  right: 5px;
}

.login > * {
  /*color: #ffffff;*/
  float: left;
  position: relative;
  margin: 0 12px 0px 5px;
  transition: 0.2s;
  cursor: pointer;
}

.login > *:hover {
  color: #555555;
}

.login > .fa-bars {
  font-size: 20px;

  margin-top: -2px;
}

.login .fa-calendar-alt,
.login .fa-lightbulb {
  top: -2px;
}

.login .user-logged {
  padding-left: 30px;
  padding-top: 8px;
  position: relative;
  margin-top: -6px;
}

.login .user-logged:hover .label {
  color: var(--bg);
}

.login .user-logged .label {
  pointer-events: none;
  transition: 0.2s;
}

.login .user-logged .picture {
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 100px;
  height: 25px;
  width: 25px;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.2s;
}

.login .user-logged:hover .picture {
  box-shadow: 0 0 0 2.5px var(--contrast-4);
}

.login .user-logged .label {
  font-size: 13px;
  color: var(--contrast-4);
  margin-right: -0.1px;
  letter-spacing: 0.4px;
}

.login .fas {
  position: relative;
  color: var(--contrast-4);
  float: left;
  position: relative;
  margin: 0 5px;
  transition: 0.2s;
  cursor: pointer;
  font-size: 20px;
  height: 25px;
  width: 25px;
  padding-top: 5px;
}

.login .fas:hover {
  color: var(--bg);
}

.login .fas .notification,
.menu .notification {
  position: absolute;
  background: #f43535;
  color: #ffffff;
  height: 16px;
  width: 16px;
  font-size: 8px;
  text-align: center;
  top: -8px;
  right: -8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .fas .notification.big,
.menu .notification.big {
  height: 17px;
  width: 17px;
  font-size: 8px;
  text-align: center;
  top: -8px;
  right: -8px;
}

.login .fa-bell {
  font-size: 16px;
}

.login .fa-sign-out-alt {
  margin-top: 2px;
}

.login .icon {
  font-size: 16px;
  height: 25px;
  width: 25px;
  margin-top: 0px;
  margin-right: 4px;
}

.login .label {
  color: var(--contrast-4);
}
.login .cog-correction {
  padding-top: 3px;
  margin-right: -2px;
}
.login .bell-correction {
  padding-top: 5px;
}

.menu-bar .picture {
  transition: 0.2s;
  cursor: pointer;
  height: 25px;
  width: 25px;
}
.menu-bar .picture:hover {
  box-shadow: 0 0 0 2px var(--contrast-7);
}

.btn-ml {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

.btn-ml:hover {
  color: #fff;
  background-color: #cc1347;
  border-color: #cc1347;
}
</style>
