/* eslint-disable */
<template>
  <div id="app">
    <menubar @menubar-loading="menubarReady = false" @menubar-ready="menubarReady = true" />
    <!-- <div class="app has-tab-menu" v-if="tabs[currentRoute] && !tabs[currentRoute].find((t) => t.hidden)"> -->
    <div class="app has-tab-menu" v-if="tabs[currentRoute] && tabs[currentRoute].filter(f => !f.hidden).length > 0">
      <div class="header">
        <menutabs :state="{ tabs: tabs[currentRoute] }" :isSuperUser="false" :tab-selected="tabSelected" @tab-select="UpdateSelectedView" />
        <!-- <menutabs :state="{ tabs: tabs[currentRoute] }" :isSuperUser="isSuperUser" :tab-selected="tabSelected" @tab-select="UpdateSelectedView" /> -->
      </div>
    </div>
    <component
      v-if="tabSelected && tabSelected.view"
      :is="tabSelected.view"
      class="component-container"
      v-bind:class="{ 'full-height': tabs[currentRoute] ? tabs[currentRoute].filter(f => !f.hidden).length == 0 : true }"
    />
    <!-- v-bind:class="{ 'full-height': tabs[currentRoute].find((t) => t.hidden) }" -->
    <dropdowncontainer :state="tooltipState" ref="tooltip" />
    <div class="popupmessage-container">
      <popupmessage v-for="(popupmessage, imessage) in popupmessages" :message="popupmessage" :key="imessage + 'i'" />
    </div>
    <div>
      <dropdowncontainer v-for="(popup, indexP) in genericPopups" :state="popup" :key="indexP + 'p'" @on-close="CheckDropdownContainers" />
    </div>
    <div>
      <popupalert v-for="(popupalert, ialert) in popupalerts" :message="popupalert" :key="ialert + 't'" />
    </div>
  </div>
</template>

<script>
import dropdowncontainer from "./components/uicomponents/dropdowncontainer";
import popupmessage from "./components/uicomponents/popupmessage.vue";
import popupalert from "./components/uicomponents/popupalert.vue";
import menubar from "./components/MenuBar";
import VueEventBus from "./vue-event-bus";
import { mapState, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    dropdowncontainer,
    menubar,
    popupmessage,
    popupalert,
  },
  data() {
    return {
      tooltipState: {
        toggled: false,
        elRelative: null,
      },
      popupmessages: [],
      popupalerts: [],
      genericPopups: [],
      menubarReady: false,
      tabs: {
        documents: [
          {
            label: "Mitarbeiter",
            view: "employeedata",
            selected: true,
            margin: true,
          },
          {
            label: "Vorlagen",
            view: "documenttemplates",
            selected: false,
            margin: true,
          } /*
          {
            label: "Datenabfragen",
            view: "datainquiries",
            selected: false,
            margin: true,
          },*/,
          /*{label: 'Duplikat Bereinigung', view: 'EmployeeDuplicateCleanup', selected: false, margin: true},*/
          /*{label: 'BMD-ID Import', view: 'BMDIDImport', selected: false, margin: true},*/
          {label: 'Import Jobs', view: 'EmployeeImportJobs', selected: false, margin: true},
          {label: 'Export', view: 'EmployeeExportOverview', selected: false, margin: true, hidden: false},
          {
            label: "Kollektivverträge",
            view: "collectiveAgreements",
            selected: false,
            margin: true,
          },
        ],
        absence: [
          {
            label: "Monatssummen",
            view: "ExportMonthly",
            selected: true,
            margin: true,
            hidden: false,
          },
          {
            label: "Abwesenheiten",
            view: "ExportDaily",
            selected: false,
            margin: true,
            hidden: false,
          },
          {
            label: "Rückstellungen",
            view: "ExportPersonnelProvisions",
            selected: true,
            margin: true,
            hidden: false,
          },
          {
            label: "Stempelzeiten",
            view: "ExportDailyActualShifts",
            selected: false,
            margin: true,
            hidden: false,
          },
          {
            label: "DATEV",
            view: "ExportSotecDashboard",
            selected: false,
            margin: true,
            hidden: false,
          },
        ],
        comparison: [
          {
            label: "Urlaub",
            view: "ComparisonHoliday",
            selected: true,
            margin: true,
            hidden: false,
          },
        ],
        company: [
          {
            label: "Einstellungen",
            view: "CompanySettings",
            selected: true,
            margin: true,
          },
          {
            label: "Benachrichtigungen",
            view: "NotificationOverview",
            selected: false,
            margin: true,
          },
          {
            label: "Benutzerrollen",
            view: "UserRoles",
            selected: false,
            margin: true,
          },
          {
            label: "Mitarbeiter Sync",
            view: "EmployeeSyncSettings",
            selected: true,
            margin: true,
            hidden: false,
          },
          {
            label: "FIBU Kreise",
            view: "FinancialAccountingGroups",
            selected: false,
            margin: true,
          },
          {
            label: "Lohnart Mapping",
            view: "WageTypes",
            selected: false,
            margin: true,
            hidden: false,
          },
          {
            label: "Beschäftigungsmodelle",
            view: "EmploymentModels",
            selected: false,
            margin: true,
            hidden: false,
          },
          {
            label: "individuelle Lohnarten",
            view: "IndividualWageTypes",
            selected: false,
            margin: true,
            hidden: false,
          },
          {
            label: "Durchschnitte",
            view: "AverageValues",
            selected: false,
            margin: true,
            hidden: false,
          },
          /*{label: 'BMD Mapping', view: 'BMDMapping', selected: false, margin: true},*/
        ],
        reports: [
          {label: 'SÜ - Abwesenheiten', view: 'ReportAbsences', selected: false, margin: true},
          {label: 'SÜ - Übersicht', view: 'ReportOverview', selected: false, margin: true, hidden: false},
          {label: 'Übersicht Variabel', view: 'ReportOverviewVariable', selected: false, margin: true, hidden: false},
        ],
        landing: [
          {
            label: "Landing",
            view: "LandingPage",
            selected: true,
            margin: true,
            hidden: true,
          },
        ],
      },
      tabSelected: null,
      currentRoute: "",
    };
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (newVal.path === oldVal.path) return;
        this.UpdateRoute(newVal);
      },
    },
    isSuperUser: {
      handler(newVal, oldVal) {
        if (newVal && !this.tabs["superadmin"]) {
          this.tabs["superadmin"] = [
            {
              label: "Version",
              view: "SuperadminVersions",
              selected: true,
              margin: true,
            },
            {
              label: "New Employees",
              view: "SuperadminNewEmployeeMail",
              selected: true,
              margin: true,
            },
            {
              label: "Company",
              view: "SuperadminCompany",
              selected: true,
              margin: true,
            },
            {
              label: "Store",
              view: "SuperadminStore",
              selected: true,
              margin: true,
            },
            {
              label: "Employee",
              view: "SuperadminEmployee",
              selected: true,
              margin: true,
            },
            {
              label: "Enums",
              view: "SuperadminEnums",
              selected: true,
              margin: true,
            },
            {
              label: "Employee Sync",
              view: "SuperadminEmployeeSyncSettings",
              selected: false,
              margin: true,
            },
            {
              label: "Transfer Service",
              view: "SuperadminTransferService",
              selected: false,
              margin: true,
            },
            {
              label: "ÖGK Clearing Meldungen",
              view: "EldaClearingOverview",
              selected: false,
              margin: true,
            },
            {
              label: "Kollektivverträge",
              view: "SuperadminCollectiveAgreements",
              selected: false,
              margin: true,
            },
          ];
        }
        if (!newVal) {
          delete this.tabs["superadmin"];
        }
        this.UpdateRoute(this.$router.history.current);
      },
    }
  },
  computed: {
    ...mapGetters(["isSuperUser"]),
  },
  created() {
    window.app = this;
    document.body.addEventListener("mousemove", this.WatchMouse);
    document.body.addEventListener("mousemove", this.MouseMoveSubscriptionHandler);
    document.body.addEventListener("mousedown", this.WatchMouse);
    document.body.addEventListener("mouseup", this.WatchMouse);
    document.addEventListener("touchstart", this.TouchEventToMouseEvent, {
      passive: false,
    });
    document.addEventListener("touchmove", this.TouchEventToMouseEvent, {
      passive: false,
    });
    document.addEventListener("touchend", this.TouchEventToMouseEvent, {
      passive: false,
    });
    document.addEventListener("touchcancel", this.TouchEventToMouseEvent, {
      passive: false,
    });
    window.mouse = { x: 0, y: 0, click: false, element: {} };
  },
  mounted() {},
  methods: {
    UpdateRoute(route) {
      console.log("Route: ", route);
      Object.values(this.tabs).forEach((t) => t.forEach((t2) => (t2.selected = false)));
      this.currentRoute = route.name;
      if (this.tabs[route.name]) {
        if (route.params.tab) {
          this.tabSelected = this.tabs[route.name].find((t) => t.view === route.params.tab);
          if (this.tabSelected != undefined && this.tabSelected.hidden) this.tabSelected = this.tabs[route.name].filter(t => !t.hidden)[0];
          if (this.tabSelected == undefined) this.tabSelected = this.tabs[route.name][0];
        } else {
          this.tabSelected = this.tabs[route.name].filter(t => !t.hidden)[0];
        }
        this.tabSelected.selected = true;
      } else {
        this.UpdateRoute({ name: "landing", params: { tab: "LandingPage" } });
      }
      if (window.menubar && window.menubar.$forceUpdate) window.menubar.$forceUpdate();
    },
    UpdateSelectedView(tab) {
      Object.values(this.tabs).forEach((t) => t.forEach((t2) => (t2.selected = false)));
      tab.selected = true;
      this.tabSelected = tab;
      this.$router.push({ name: this.currentRoute, params: { tab: tab.view } });
    },
    MouseMoveSubscribe(key, subscribeFunction) {
      this.mouseMoveSubscriptions[key] = subscribeFunction;
    },
    MouseMoveUnsubscribe(key) {
      delete this.mouseMoveSubscriptions[key];
    },
    MouseMoveSubscriptionHandler($event) {
      for (let x in this.mouseMoveSubscriptions) {
        this.mouseMoveSubscriptions[x](window.mouse.element);
      }
    },
    ToggleTooltip(element, state) {
      let nstate = { tooltip: true, elRelative: element, ...state };
      if (!nstate.pointerDirection) nstate.pointerDirection = 2;
      if (!nstate.pinBottomRight && !nstate.pinBottomLeft && !nstate.pinTopRight && !nstate.pinTopLeft) nstate.pinBottom = true;
      if (!nstate.offsety) nstate.offsety = 5;
      if (!nstate.offsetx) nstate.offsetx = 0;
      nstate.posx = window.mouse.x;
      nstate.posy = window.mouse.y;
      this.tooltipState = nstate;
      if (!nstate.pinBottom) this.tooltipState.pinBottom = false;
      if (!nstate.pinBottomLeft) this.tooltipState.pinBottomLeft = false;
      if (!nstate.pinBottomRight) this.tooltipState.pinBottomRight = false;
      if (!nstate.pinTopRight) this.tooltipState.pinTopRight = false;
      if (!nstate.pinTopLeft) this.tooltipState.pinTopLeft = false;
    },
    RepositionTooltip() {
      let view = this;
      this.$refs.tooltip.Reposition();
    },
    CheckDropdownContainers(dropdowncontainer) {
      let view = this;
      if (this.$children.filter((c) => view.genericPopups.find((g) => c.state === g)).every((c) => !c.toggled)) view.genericPopups = [];
    },
    TouchEventToMouseEvent($event) {
      var touches = $event.changedTouches,
        first = touches[0],
        type = "";
      switch ($event.type) {
        case "touchstart":
          type = "mousedown";
          break;
        case "touchmove": {
          if (window.roster && window.roster.dayMode) $event.preventDefault();
          type = "mousemove";
          break;
        }
        case "touchend":
          type = "mouseup";
          break;
        default:
          return;
      }

      var simulatedEvent = document.createEvent("MouseEvent");
      simulatedEvent.initMouseEvent(type, true, true, window, 1, first.screenX, first.screenY, first.clientX, first.clientY, false, false, false, false, 0 /*left*/, null);

      first.target.dispatchEvent(simulatedEvent);
      //$event.preventDefault();
    },
    WatchMouse($event) {
      if ($event.type === "mousedown" && $event.which === 1) window.mouse.click = true;
      if ($event.type === "mouseup" && $event.which === 1) window.mouse.click = false;
      if ($event.type === "mousemove") {
        window.mouse.x = $event.clientX;
        window.mouse.y = $event.clientY;
        window.mouse.element = document.elementFromPoint($event.clientX, $event.clientY);
      }
    },
    addAdditionalCompanyTabs: function(companyId){
      let view = this;

      // Add or remove ReportOverview based on company id
      const companyIds = [
        "30", // Mustermann GmbH
        "116", // Willy Dany
        "154",  // Gerd Vogel
        "161",  // Michael Betzien Systemgastronomie eK
        "168", // Marcus kleine Burgerstube
        "170", // Mike Kraft
        "173", // Andreas Schoft
        "175", // Blumroth
        "176", // Kraft Systemgastronomie
        "180", // Fietz Sievershabgen
        "181", // Fietz Kröpeliner Straße
        "182", // Fietz Schwerin
        "183", // Fietz Wismar
        "184", // Fietz Bentwisch

        //"198", // Reisert
        "199", // Michael Gollor
        //"200" // Beer
        "206"
      ];
      let reportsOverview = view.tabs.reports.find((t) => t.view === 'ReportOverview');
      let reportAbsences = view.tabs.reports.find((t) => t.view === 'ReportAbsences');
      if (companyIds.includes(""+companyId)) {
        reportsOverview.hidden = false;
      } else {
        reportsOverview.hidden = true;
        // set default view when ReportOverview was selected on company change
        if (view.tabSelected && view.tabSelected.view === 'ReportOverview') {
          view.UpdateSelectedView(reportAbsences);
        }
      }

    },
    addAdditionalExternalSoftwareTabs: function(externalSoftware) {
      let view = this;

      let employeedata = view.tabs.documents.find((t) => t.view === 'employeedata');
      let employeeExportOverview = view.tabs.documents.find((t) => t.view === 'EmployeeExportOverview');

      let exportMonthly = view.tabs.absence.find((t) => t.view === 'ExportMonthly');
      let exportDaily = view.tabs.absence.find((t) => t.view === 'ExportDaily');
      let exportPersonnelProvisions = view.tabs.absence.find((t) => t.view === 'ExportPersonnelProvisions');
      let exportDailyActualShifts = view.tabs.absence.find((t) => t.view === 'ExportDailyActualShifts');
      let exportSotecDashboard = view.tabs.absence.find((t) => t.view === 'ExportSotecDashboard');

      let companySettings = view.tabs.company.find((t) => t.view === 'CompanySettings');
      let employeeSyncSettings = view.tabs.company.find((t) => t.view === 'EmployeeSyncSettings');
      let wageTypes = view.tabs.company.find((t) => t.view === 'WageTypes');
      let employmentModels = view.tabs.company.find((t) => t.view === 'EmploymentModels');
      let individualWageTypes = view.tabs.company.find((t) => t.view === 'IndividualWageTypes');
      let averageValues = view.tabs.company.find((t) => t.view === 'AverageValues');

      let reportAbsences = view.tabs.reports.find((t) => t.view === 'ReportAbsences');
      let reportOverviewVariable = view.tabs.reports.find((t) => t.view === 'ReportOverviewVariable');

      // ***************************************
      // ***************** BMD *****************
      // ***************************************
      if (externalSoftware === 0) {
        // documents
        employeeExportOverview.hidden = false;

        // absence
        exportMonthly.hidden = false;
        exportDaily.hidden = false;
        exportPersonnelProvisions.hidden = false;
        exportDailyActualShifts.hidden = true;
        exportSotecDashboard.hidden = true;
        if (view.tabSelected && ['ExportDailyActualShifts', 'ExportSotecDashboard'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(exportMonthly);
        }

        // reports
        reportOverviewVariable.hidden = true;
        if (view.tabSelected && ['ReportOverviewVariable'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(reportAbsences);
        }

        // company
        employeeSyncSettings.hidden = false;
        wageTypes.hidden = false;
        employmentModels.hidden = true;
        individualWageTypes.hidden = true;
        averageValues.hidden = true;
        if (view.tabSelected && ['EmploymentModels', 'IndividualWageTypes', 'AverageValues'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(companySettings);
        }
      }
      // ***************************************
      // ***************** DPW *****************
      // ***************************************
      else if (externalSoftware === 1) {
        // documents
        employeeExportOverview.hidden = true;
        if (view.tabSelected && ['EmployeeExportOverview'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(employeedata);
        }

        // absence
        exportMonthly.hidden = true;
        exportDaily.hidden = false;
        exportPersonnelProvisions.hidden = true;
        exportDailyActualShifts.hidden = false;
        exportSotecDashboard.hidden = true;
        if (view.tabSelected && ['ExportMonthly', 'ExportPersonnelProvisions', 'ExportSotecDashboard'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(exportDaily);
        }

        // reports
        reportOverviewVariable.hidden = true;
        if (view.tabSelected && ['ReportOverviewVariable'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(reportAbsences);
        }

        // company
        employeeSyncSettings.hidden = true;
        wageTypes.hidden = true;
        employmentModels.hidden = true;
        individualWageTypes.hidden = true;
        averageValues.hidden = true;
        if (view.tabSelected && ['EmployeeSyncSettings', 'WageTypes', 'EmploymentModels', 'IndividualWageTypes', 'AverageValues'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(companySettings);
        }
      // ***************************************
      // ************* SOTEC DATEV *************
      // ***************************************
      } else if (externalSoftware === 2) {
        // documents
        employeeExportOverview.hidden = true;
        if (view.tabSelected && ['EmployeeExportOverview'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(employeedata);
        }

        // absence
        exportMonthly.hidden = true;
        exportDaily.hidden = true;
        exportPersonnelProvisions.hidden = true;
        exportDailyActualShifts.hidden = true;
        exportSotecDashboard.hidden = false;
        if (view.tabSelected && ['ExportMonthly', 'ExportDaily', 'ExportPersonnelProvisions', 'ExportDailyActualShifts'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(exportSotecDashboard);
        }

        // reports
        reportOverviewVariable.hidden = false;

        // company
        employeeSyncSettings.hidden = true;
        wageTypes.hidden = true;
        employmentModels.hidden = false;
        individualWageTypes.hidden = false;
        averageValues.hidden = false;
        if (view.tabSelected && ['EmployeeSyncSettings', 'WageTypes'].includes(view.tabSelected.view)) {
          view.UpdateSelectedView(companySettings);
        }
      }
      console.log("current routes: ", view.tabs[view.currentRoute]);
    }
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.fullwidth {
  max-width: 100% !important;
}
/*
 * General styles
 */
body {
  /*background-repeat: no-repeat;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(122, 144, 6));*/
  /*background-image: url("./assets/connectwork.png")*/
  background-color: whitesmoke;
}

.card-container.card {
  max-width: 100%;
  padding: 40px 40px;
}

.btn {
  font-weight: 700;
  height: 36px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

/*
 * Card component
 */
.card {
  background-color: #f7f7f7;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  /*-moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;*/
}

/*
 * Form styles
 */
.profile-name-card {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 0;
  min-height: 1em;
}

.reauth-email {
  display: block;
  color: #404040;
  line-height: 2;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
  direction: ltr;
  height: 44px;
  font-size: 16px;
}

.form-signin input[type="email"],
.form-signin input[type="password"],
.form-signin input[type="text"] .form-signin input[type="number"],
.form-signin button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  border-color: rgb(104, 145, 162);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-signin {
  /*background-color: #4d90fe; */
  background-color: rgb(104, 145, 162);
  /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/

  font-weight: 700;
  font-size: 14px;
  height: 36px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
  background-color: rgb(122, 144, 6);
}

.forgot-password {
  color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus {
  color: rgb(12, 97, 33);
}
.custom-dialog div {
  box-sizing: border-box;
}
.custom-dialog .dialog-flex {
  width: 100%;
  height: 100%;
}
.custom-dialog .dialog-content {
  flex: 1 0 auto;
  width: 100%;
  padding: 15px;
  font-size: 14px;
}
.custom-dialog .dialog-c-title {
  font-weight: 600;
  padding-bottom: 15px;
}
.custom-dialog .dialog-c-text {
}
.custom-dialog .custom-dialog-buttons {
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  border-top: 1px solid #eee;
}
.custom-dialog .custom-dialog-buttons-none {
  width: 100%;
  padding-bottom: 15px;
}
.custom-dialog-button {
  font-size: 12px !important;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  color: inherit;
  font: inherit;
  outline: none;
}
.custom-dialog-button:hover {
  background: rgba(0, 0, 0, 0.01);
}
.custom-dialog-button:active {
  background: rgba(0, 0, 0, 0.025);
}
.custom-dialog-button:not(:first-of-type) {
  border-left: 1px solid #eee;
}
</style>

<style>
@import "./assets/styles/colors_dark.css";
@import "./assets/styles/colors_light.css";
@import "./assets/styles/md.css";
@import "./assets/styles/fontawesome.css";
@import "./assets/styles/md_750px.css";
@import "./assets/styles/md_1380px.css";
@import "./assets/styles/dropdown.css";
@import "./assets/styles/bootstrap.css";
@import "./assets/styles/bootstrap-vue.css";
@import "./assets/styles/all.min.css";

.component-container {
  position: absolute;
  top: 90px !important;
  left: 0;
  width: 100%;
  height: calc(100vh - 90px) !important;
  overflow-x: auto;
  overflow-y: auto;
}

.component-container.full-height {
  top: -7px !important;
  left: 0;
  width: 100%;
  height: calc(100vh - 55px) !important;
}
</style>
